<div>
  <div>
    <div style="display: block; align-content: center; padding-bottom: 50px;">
      <canvas  #FunctionalRequirementsChart baseChart
        [datasets]="bubbleChartData"
        [options]="bubbleChartOptions"
        [legend]="bubbleChartLegend"
        [chartType]="bubbleChartType">
      </canvas>
    </div>
  </div>
</div>
