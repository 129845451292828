import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-delete-contract-modal',
  templateUrl: './delete-contract-modal.component.html',
  styleUrls: ['./delete-contract-modal.component.scss']
})
export class DeleteContractModalComponent implements OnInit {

  contractId!: number;
  contractName!: string;

  deleteContract(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/delete-contract';

    const options = {
      responseType: 'json' as const
    };

    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

        this.toastService.showInfoToast(response.message);

        this.modalRef.hide();

    })
  }

  constructor(public modalRef: BsModalRef, private httpService: HttpClient,
    private loadingSpinner: SiNewtonLoadingService, private toastService: CustomToastService) { }

  ngOnInit(): void {
  }

}
