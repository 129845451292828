<div class="modal-header">
  <h4 class="modal-title pull-left">
    Add Interfaces and Addons to {{ contractName }}
  </h4>
</div>
<div #modalBody class="modal-body">

  <app-interface-data [contractId]=contractId [contractName]=contractName [userRole]="userRole"></app-interface-data>
  <app-addons-data [contractId]=contractId [contractName]=contractName [userRole]="userRole"></app-addons-data>
  <app-libraries-data [contractId]=contractId [contractName]=contractName [userRole]="userRole"></app-libraries-data>

  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modalRef.hide()">Close</button>
  </div>
</div>
