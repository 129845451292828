<div #ContractOverview>
  <si-newton-table [rows]="contracts" [loading]="(contracts) === null" [rowsPerPage]="15">

    <siNewtonTableColumn key="contract_name" name="Contract Name">
      <div *siNewtonTableCell="let row = row">
        {{ row.contract_name }}
      </div>
    </siNewtonTableColumn>

    <siNewtonTableColumn key="critical" [disableFilter]="true" [widthFactor]="0.4">
      <div *siNewtonTableCell="let row = row">
        <i *ngIf="row.critical == true" class="bi bi-exclamation-triangle-fill"
        style="color: red; font-size: 1.5em; float: right;">
        </i>
      </div>
    </siNewtonTableColumn>

    <siNewtonTableColumn name="LoA" [disableFilter]="true"  [disableSort]="true" [widthFactor]="0.6">
      <ng-template siNewtonTableCell  let-value='value' let-row="row" style="text-align: center;">

        <label class="switch">
          <input type="checkbox" [disabled]="userRole != 'admin'" [checked]="row.loa_status" (click)="changeLcsAndLoaStatus(row.contract_id, !row.loa_status, 'loa')">
          <span class="slider round"></span>
        </label>
      </ng-template>

    </siNewtonTableColumn>

    <siNewtonTableColumn name="Active" [disableFilter]="true" [disableSort]="true" [widthFactor]="0.6">
      <ng-template siNewtonTableCell  let-value='value' let-row="row" style="text-align: center;">

      <label class="switch">
        <input type="checkbox" [disabled]="userRole != 'admin'" [checked]="row.lcs_status" (click)="changeLcsAndLoaStatus(row.contract_id, !row.lcs_status, 'lcs')">
        <span class="slider round"></span>
      </label>
    </ng-template>
    </siNewtonTableColumn>

    <siNewtonTableColumn [widthFactor]="0.8" key="customer" name="Customer"></siNewtonTableColumn>
    <siNewtonTableColumn [widthFactor]="0.7" key="start_date" name="Start"></siNewtonTableColumn>
    <siNewtonTableColumn [widthFactor]="0.7" key="end_date" name="End"></siNewtonTableColumn>

    <siNewtonTableColumn name="Year of PCS neo Evolution">
      <ng-template siNewtonTableCell  let-value='value' let-row="row" width="">{{row.upgrade_pcs_neo_year.replace(9999, 'No Evolution set')}}</ng-template>
    </siNewtonTableColumn>

    <siNewtonTableColumn [widthFactor]="0.8" key="bom_date" name="BOM Date"></siNewtonTableColumn>

    <siNewtonTableColumn [widthFactor]="1" key="contract_owner" name="Contract Owner"></siNewtonTableColumn>

    <siNewtonTableColumn [widthFactor]="1"  [disableFilter]="true" [disableSort]="true">
      <ng-template siNewtonTableCell  let-value='value' let-row="row" width="">
        <label title="SW Availability Check" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
        [ngStyle]="{'background-color': row.sw_check_status}"
        (click)="openUpgradeTimes(row)">
          <i class="bi bi-disc"></i>
      </label>

      <button title="HW Compatibility Check" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
      [ngStyle]="{'background-color': row.hw_check_status}"
      (click)="openHwCheckDetails(row)"
      [disabled]="row.bom_date == 'No BOM uploaded'">
        <i class="bi bi-cpu"></i>
      </button>

      <label title="Additional Data" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
      [ngStyle]="{'background-color': row.additional_data_status}"
      (click)="openAdditionalData(row)">
        <i class="bi bi-clipboard-plus"></i>
      </label>


      </ng-template>

    </siNewtonTableColumn>

    <siNewtonTableColumn *ngIf="userRole != 'orga'" [widthFactor]="1.2" [disableFilter]="true" [disableSort]="true">
      <ng-template siNewtonTableCell  let-value='value' let-row="row" width="">
        <label title="Upload BOM" class="btn btn-success" style="float: left; margin: 1px; border: 0px;" (click)="uploadBomModal(row.contract_id, row.contract_name)">
          <i class="bi bi-table"></i>
        </label>

        <label title="Update Contract Data" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
        (click)="openContractDataModel(row.contract_id, row.contract_name)">
          <i class="bi bi-pencil"></i>
        </label>

        <label title="Release Documents" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
        (click)="openReleaseDocumentsModal(row.contract_id, row.contract_name)">
          <i class="bi bi-box"></i>
        </label>


      <!--
        <label class="btn btn-success" style="float: left; margin: 1px; border: 0px;" title="Upload Release Document">
          <i class="bi bi-upload"></i>
          <input #documentFileInput type="file" id="documentFileInput"
          (change)="uploadReleaseDocument(row.contract_id, $event, row.contract_name)">
        </label>

        <button [disabled]="row.document_name == null" type="button" class="btn btn-secondary" style="float: left; margin: 1px; border: 0px;"
        title="Download Release document"
        (click)="downloadReleaseDocument(row.document_name)"><i class="bi bi-download"></i></button> -->

        <label titel="Delete Contract" class="btn btn-primary" style="float: left; margin: 1px; border: 0px;" (click)="openDeleteContractModal(row.contract_id, row.contract_name)">
          <span class="newton-delete"></span>
        </label>
      </ng-template>

    </siNewtonTableColumn>

    <div no-data>
      No projects found
    </div>

  </si-newton-table>

</div>
