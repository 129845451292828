import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ReportService } from 'src/app/services/report.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FunctionalRequirementsComponent } from '../functional-requirements/functional-requirements.component';
import { ContractModel } from 'src/app/data-models/contract';


@Component({
  selector: 'app-managementview',
  templateUrl: './managementview.component.html',
  styleUrls: ['./managementview.component.scss']
})
export class ManagementviewComponent implements OnInit {

  @ViewChild('ContractOverview') contractOverview!: ElementRef;
  @ViewChild(FunctionalRequirementsComponent) functionRequirements!: FunctionalRequirementsComponent;

  @Input()
  contracts: Array<ContractModel> = [];
  imageFunctionalRequirements!: any;
  contractOverviewImage!: any;
  functionalRequirementsImage!: any;

  @Output() openHwCheckDetailsEvent = new EventEmitter();
  @Output() openUpgradeTimesEvent = new EventEmitter();
  @Output() openAdditionalDataEvent = new EventEmitter();
  @Output() getAllContractsEvent = new EventEmitter();

  openHwCheckDetails(contractRow: ContractModel) {
    this.openHwCheckDetailsEvent.emit(contractRow);
  }

  openUpgradeTimes(contractRow: ContractModel){
    this.openUpgradeTimesEvent.emit(contractRow);
  }

  openAdditionalData(contractRow: ContractModel){
    this.openAdditionalDataEvent.emit(contractRow);
  }

  async generateReport(){

    this.loadingSpinner.startLoading();

    const canvasContractOverview = await html2canvas(this.contractOverview.nativeElement);

    this.contractOverviewImage = canvasContractOverview.toDataURL("image/jpeg", 1);

    // // Functional Requirements is a Cancas yet, so no Cast is need
    const functionalRequirementsChart = this.functionRequirements.functionalRequirementsChart.nativeElement;
    console.log(functionalRequirementsChart);
    this.functionalRequirementsImage = functionalRequirementsChart.toDataURL("image/png", 1);


    var pdf = new jsPDF('portrait', 'px',
      [
        canvasContractOverview.height + functionalRequirementsChart.height + 200,
        canvasContractOverview.width > functionalRequirementsChart.width ?  canvasContractOverview.width : functionalRequirementsChart.width

      ]
    );


    let insertHeight = 25;

    pdf.setFont("Arial", "italic", "bold");
    pdf.setFontSize(30);

    pdf.text('Contract Overview', 15, insertHeight);

    insertHeight = insertHeight + 25;

    pdf.addImage(this.contractOverviewImage, 0, insertHeight, canvasContractOverview.width, canvasContractOverview.height);

    insertHeight = insertHeight + canvasContractOverview.height + 25;

    pdf.text('Overview for Functional Requirements', 15, insertHeight);

    insertHeight = insertHeight + 25;

    pdf.addImage(this.functionalRequirementsImage, 0, insertHeight, functionalRequirementsChart.width, functionalRequirementsChart.height);

    insertHeight = insertHeight + functionalRequirementsChart.height + 25;

    pdf.text('Detailed view of requirements per function', 15, insertHeight);

    insertHeight = insertHeight + 50;

    pdf = await this.reportService.createTableOfDetailedFunctionOverview(pdf, insertHeight);

    insertHeight = insertHeight + 25;

    pdf.setFont("helvetica");
    pdf.setFontSize(16);

    pdf.text('Automatically created via PCS neo Contract Dashboard on ' + new Date().toLocaleDateString(), pdf.internal.pageSize.getWidth() - 300, pdf.internal.pageSize.getHeight() - 50);

    pdf.save('PCS neo Contract Overview.pdf');

    this.loadingSpinner.stopLoading();

  }


  constructor(private loadingSpinner: SiNewtonLoadingService, private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.getAllContractsEvent.emit();
  }

}
