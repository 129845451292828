import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CustomToastService } from './custom-toast.service';

@Injectable({
  providedIn: 'root'
})
export class FileTransferService {

  public downLoadFileFunc(data: any, type: string, fileName: string) {

    const blob = new Blob([data], { type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {

      window.navigator.msSaveOrOpenBlob(blob, fileName);
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {

      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  public async uploadReleaseDocumentFile(contractId: number, fileName: string, documentType: string, document: File) {

    const endpoint = environment.baseUrl + `/file-management/upload-release-document`;

    const formData: FormData = new FormData();

    formData.append('contractId', contractId.toString());
    formData.append('fileName', fileName);
    formData.append('fileType', 'Not relevant');
    formData.append('documentType', documentType);
    formData.append('document', document);

    const options = {
      responseType: 'json' as const
    };

    try {
      const resultOfUpload = await this.httpService.post(endpoint, formData, options).toPromise();

      this.toastService.showInfoToast((resultOfUpload as any).message);
    } catch(err){
      this.toastService.showErrorToast((err as any).message);
    }


  }

  public async downloadReleaseDocumentFile(internalFileName: string, visibleFileName: string){

        const endpoint = environment.baseUrl + '/file-management/download-release-document?internalFileName=' + internalFileName;
        const options = {
          responseType: 'blob' as const,
          observe: 'body' as const
        };

        this.httpService.get(endpoint, options).subscribe(blob => {

          saveAs(blob, visibleFileName);

        }, (errorResponse: HttpErrorResponse) => {

          this.toastService.showErrorToast('Could not download the file');

        });
  }

  public async deleteReleaseDocumentFile(documentId: number) {

    const endpoint = environment.baseUrl + `/file-management/delete-release-document`;

    const formData: FormData = new FormData();

    formData.append('documentId', documentId.toString());


    const options = {
      responseType: 'json' as const
    };

    try {
      const response = await this.httpService.post(endpoint, formData, options).toPromise();

      this.toastService.showInfoToast((response as any).message);
    } catch(err){
      this.toastService.showErrorToast((err as any).message);
    }


  }



  constructor(private httpService: HttpClient, private toastService: CustomToastService) { }
}
