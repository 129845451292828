<h4>Libraries entries</h4>

  <form [formGroup]="libraryForm" class="col-md-12" style="align-items: center;">
    <div *ngFor="let entry of libraryEntries; let i = index">
      <div class="row">

        <div class="col-md-3">
          <si-newton-form-group label="Interface name">
            <input
              type="string"
              siNewton
              formControlName="{{ 'entryName' + i }}"
              [(ngModel)]="entry.entry_name"
            />
          </si-newton-form-group>
        </div>
        <div class="col-md-4">
          <si-newton-form-group label="Comment">
            <input
              type="string"
              siNewton
              formControlName="{{ 'comment' + i }}"
              [(ngModel)]="entry.comment"
            />
          </si-newton-form-group>
        </div>



        <div class="col-md-2">

          <fieldset [disabled]="userRole != 'admin'">
            <div class="form-check form-check-inline" style="margin-right: 0.5rem">
              <input id="{{ 'radio-a' + i }}" class="form-check noRelease" type="radio" value="red"
              formControlName="{{ 'checkStatusEntry' + i }}" [(ngModel)]="entry.status"
              (change)="updateEntryStatus(entry.id, entry.status)">
            </div>
            <div class="form-check form-check-inline" style="margin-right: 0.5rem">
              <input id="{{ 'radio-b' + i }}" class="form-check limitedRelease" type="radio" value="orange"
              formControlName="{{ 'checkStatusEntry' + i }}" [(ngModel)]="entry.status"
              (change)="updateEntryStatus(entry.id, entry.status)">
            </div>
            <div class="form-check form-check-inline" style="margin-right: 0.5rem">
              <input id="{{ 'radio-c' + i }}" class="form-check release" type="radio" value="green"
              formControlName="{{ 'checkStatusEntry' + i }}" [(ngModel)]="entry.status"
              (change)="updateEntryStatus(entry.id, entry.status)">
            </div>

          </fieldset>

        </div>

        <div class="col-md-3">

          <fieldset [disabled]="this.userRole != 'admin' && this.userRole != 'user'">
            <label class="btn btn-success" style="min-width: 0px; margin-left:0.75rem" title="Upload ZIP">
              <i class="bi bi-upload"></i>
              <input #documentFileInput type="file" id="documentFileInput" accept="application/msexcel"
              (change)="uploadFile(entry.id, $event)">
            </label>

              <button [disabled]="entry.file_name == null" type="button" class="btn btn-secondary" style="min-width: 0px; margin-left:0.75rem"
              title="Download ZIP" (click)="downloadFile(entry.file_name)"><i class="bi bi-download"></i></button>

              <button type="button" class="btn btn-primary" style="min-width: 0px; margin-left:0.75rem"
              title="Update Entry"
              (click)="updateEntry(entry.id, entry.entry_name, entry.comment)"><span class="newton-edit"></span></button>

              <button type="button" class="btn btn-primary" style="min-width: 0px; margin-left:0.75rem"
              (click)="deleteEntry(entry.id)"><span class="newton-delete"></span></button>
          </fieldset>

        </div>



      </div>


    </div>

    <div class="row">
      <div class="col-md-3">
        <si-newton-form-group label="Library name">
          <input
            formControlName="entryName"
            type="string"
            placeholder="Please type in name of Library"
            siNewton
          />
        </si-newton-form-group>
      </div>
      <div class="col-md-4">
        <si-newton-form-group label="Comment">
          <input
          formControlName="comment"
            type="string"
            placeholder="Please type in additional Information"
            siNewton
          />
        </si-newton-form-group>
      </div>

      <div class="col-md-3">

      </div>

      <div class="col-md-2">

        <button type="button" class="btn btn-success col-md-2" [disabled]="libraryForm.invalid" *ngIf="this.userRole == 'admin' || this.userRole == 'user'"
        (click)="addEntry()"><i class="bi bi-plus-square"></i></button>

      </div>

    </div>
  </form>
