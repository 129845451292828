import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { MlfbModel } from 'src/app/data-models/mlfb';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mlfb-view',
  templateUrl: './mlfb-view.component.html',
  styleUrls: ['./mlfb-view.component.scss']
})
export class MlfbViewComponent {

  mlfbs: Array<MlfbModel> = [];


  constructor(private httpService: HttpClient, private loadingSpinner: SiNewtonLoadingService,
    private toastService: CustomToastService) {
      this.getAllUsedMlfbs();
    }

  getAllUsedMlfbs() {

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/mlfb-overview/get-all-mlfbs-of-contracts';

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    this.httpService.get(endpoint, options).subscribe(
      (response: any) => {

        console.log(response);
        this.mlfbs = response;

        this.loadingSpinner.stopLoading();

      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorToast('Could not fetch mlfbs.\n' + errorResponse.error.errorMessage);
      });
  }

}
