  <form [formGroup]="contractCreationForm" class="col-md-12" style="align-items: center;">

    <div class="row">

      <div class="col">
        <si-newton-form-group label="Select type of request">
          <si-newton-dropdown
                name="requestType"
                [dropdownOptions]="requestTypeValues"
                [valueProvider]="getRequestTypeValue"
                formControlName="requestType"
              >
          </si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col"></div>

    </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Contract name" [errorResolverMap]="{required: 'Value is required'}">
          <input id="contractName" formControlName="contractName"
            siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Contract Number" [errorResolverMap]="{required: 'Value is required'}">
          <input id="contractNumber" formControlName="contractNumber"
            siNewton>
        </si-newton-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <si-newton-form-group label="Customer" [errorResolverMap]="{required: 'Value is required'}">
          <input id="customer" formControlName="customer"
            siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Contract Start" [errorResolverMap]="{required: 'Value is required'}">
          <input id="startDate" formControlName="startDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }" (ngModelChange)="formatStartDate()"
            siNewton>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Contract End" [errorResolverMap]="{required: 'Value is required'}">
          <input id="endDate" formControlName="endDate" readonly
            siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Select duration of contract in years">
          <si-newton-dropdown
                name="contractDuration"
                [dropdownOptions]="contractDurationValues"
                [valueProvider]="getContractDurationValue"
                formControlName="contractDuration"
                (ngModelChange)="calculateEndDate()"
              >
          </si-newton-dropdown>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Contact person (full name)" [errorResolverMap]="{required: 'Value is required'}">
          <input id="contactPerson" formControlName="contactPerson"
            siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">

    <si-newton-form-group label="Contact Department" [errorResolverMap]="{required: 'Value is required'}">
      <input id="contactDepartment" formControlName="contactDepartment"
        siNewton>
    </si-newton-form-group>
      </div>

    </div>

    <button type="button" class="btn btn-success col-md-3" style="float: right;"
    (click)="createContract()" [disabled]="!(contractCreationForm.valid)">Create contract</button>
  </form>


