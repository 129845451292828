import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-measeurements',
  templateUrl: './edit-measeurements.component.html',
  styleUrls: ['./edit-measeurements.component.scss']
})
export class EditMeaseurementsComponent implements OnInit {

  @Input()
  contractId!: number;
  cricialComponents!: any;

  editMeassurementForm!: FormGroup;

  getCriticalComponentsOfContruct(){
    const endpoint = environment.baseUrl + '/contract-management/get-critical-components-of-contract?contractId=' + this.contractId;

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    this.httpService.get(endpoint, options).subscribe((result: any) => {

      this.cricialComponents = result;
      console.log(this.cricialComponents);
    });
  }

  updateRelevanceOfCriticalComponent(mlfb: string){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/update-relevance-of-critical-component';
    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('mlfb', mlfb);
    formData.append('isComponentNotRelevant', this.cricialComponents.filter((element: { mlfb: string; }) => element.mlfb == mlfb)[0].is_not_relevant);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();
    }, (err: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorFromBackendToast(err);
    })
  }

  updateMeasurePlacedForCriticalComponent(mlfb: string){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/update-measure-placed-of-critical-component';
    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('mlfb', mlfb);
    formData.append('measurePlaced', this.cricialComponents.filter((element: { mlfb: string; }) => element.mlfb == mlfb)[0].is_measure_placed);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

    }, (err) => {
        this.loadingSpinner.stopLoading();
    })
  }

  updateMeasureCommentForCriticalComponent(mlfb: string){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/update-measure-comment-of-critical-component';
    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('mlfb', mlfb);
    formData.append('measureComment', this.cricialComponents.filter((element: { mlfb: string; }) => element.mlfb == mlfb)[0].measure_comment);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

    }, (err) => {
        this.loadingSpinner.stopLoading();
    })
  }


  constructor(private httpService: HttpClient, private loadingSpinner: SiNewtonLoadingService,
    private toastService: CustomToastService) { }

  ngOnInit(): void {
    this.getCriticalComponentsOfContruct();
  }
}
