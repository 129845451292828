import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { Entry } from 'src/app/data-models/entry';
import { AdditionalDataRestService } from 'src/app/services/additional-data-rest.service';
import { CustomHttpService } from 'src/app/services/custom-http.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';


@Component({
  selector: 'app-addons-data',
  templateUrl: './addons-data.component.html',
  styleUrls: ['./addons-data.component.scss']
})
export class AddonsDataComponent implements OnInit {

  @Input()
  userRole!: string;

  documentFile!: File;

  addonForm: FormGroup;

  @Input() contractName!: string;
  @Input() contractId!: number;
  addonEntries: Array<Entry> = [];

  checkStatusEntry!: string;

  uploadFile(entryId: number, e: Event) {

    this.loadingSpinner.startLoading();

    this.documentFile = (e.target as HTMLInputElement).files!.item(0)!;

    this.additionalDataService.uploadDocumentForEntry(entryId, this.documentFile, this.contractName).then( res => {

      this.loadingSpinner.stopLoading();
      this.toastService.showInfoToast(res);

      this.additionalDataService.getEntries(this.contractId, 'addon').then( (res: any) => {
        this.addonEntries = res;

        this.addStatusFormControls();
      }).catch(errorMessage => {
        console.log(errorMessage);
        this.toastService.showErrorToast(errorMessage);
      });

    }).catch(errorMessage => {
      this.loadingSpinner.stopLoading();

      this.toastService.showErrorToast(errorMessage);
    });

  }

  async downloadFile(fileName: string){

    await this.customHttpService.downloadFile(fileName, 'documents');

  }

  async addEntry(){

    await this.additionalDataService.addEntry(this.contractId, 'addon', this.addonForm.get('addonName')?.value,
    this.addonForm.get('comment')?.value).then( async (res: any) => {
      this.addonForm.reset();

      this.addonEntries = res;
      this.addStatusFormControls();
    }).catch(errorMessage => {
      console.log(errorMessage);
      this.toastService.showErrorToast(errorMessage);
    });
  }

  async updateEntry(entryId: number, entryName: string, entryComment: string){

    this.loadingSpinner.startLoading();

    await this.additionalDataService.updateEntry(this.contractId, 'addon', entryId, entryName, entryComment).then( async (res: any) => {
      this.addonForm.reset();

      this.addonEntries = res;
      this.addStatusFormControls();

      this.toastService.showInfoToast('Updated entry successfuelly');
    }).catch(errorMessage => {
      console.log(errorMessage);
      this.toastService.showErrorToast(errorMessage);

    }).finally(() => {
      this.loadingSpinner.stopLoading();
    });
  }

  async deleteEntry(entryId: number){

    await this.additionalDataService.deleteEntry(this.contractId, 'addon', entryId).then( async (res: any) => {

      this.addonForm.reset();
      this.addonEntries = res;

      this.addStatusFormControls();

    }).catch(errorMessage => {
      console.log(errorMessage);
      this.toastService.showErrorToast(errorMessage);
    });
  }

  async updateEntryStatus(entryId: number, status: String){
    await this.additionalDataService.updateEntryStatus(entryId, status).catch(errorMessage => {
      this.toastService.showErrorToast(errorMessage);
    })
  }

  addStatusFormControls(){
    for (let i = 0; i < this.addonEntries.length ; i++) {

      this.addonForm.addControl(
        'checkStatusEntry' + i.toString(),
        new FormControl(null, null)
      );

      this.addonForm.addControl(
        'addonName' + i.toString(),
        new FormControl(null, null)
      );

      this.addonForm.addControl(
        'comment' + i.toString(),
        new FormControl(null, null)
      );
    }
  }

  constructor(private loadingSpinner: SiNewtonLoadingService, private formBuilder: FormBuilder, private customHttpService: CustomHttpService,
    private additionalDataService: AdditionalDataRestService, private toastService: CustomToastService) {
    this.addonForm = this.formBuilder.group({
      addonName: [null, Validators.required],
      comment: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadingSpinner.startLoading();

    this.additionalDataService.getEntries(this.contractId, 'addon').then( (res: any) => {
      this.addonEntries = res;

      this.addStatusFormControls();

      if(this.userRole != 'admin' && this.userRole != 'user'){
        this.addonForm.disable();
      }
    }).catch(errorMessage => {
      console.log(errorMessage);
      this.toastService.showErrorToast(errorMessage);
    });


    this.loadingSpinner.stopLoading();
  }

}
