import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiNewtonToastService, SiToastTypes } from '@simpl/newton-ng/toast';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {

  showInfoToast(infoText: string) {
    this.toastService.showToast({
      content: infoText,
      type: SiToastTypes.SUCCESS
    });
  }

  showErrorToast(errorText: string) {

    this.toastService.showToast({
      content: errorText,
      type: SiToastTypes.DANGER
    });
  }

  showErrorFromBackendToast(error: HttpErrorResponse) {

    this.toastService.showToast({
      content: 'Backend error: ' + error.error.errorMessage,
      type: SiToastTypes.DANGER
    });
  }

  showErrorAsJsonFromBackendToast(error: {errorMessage: string}) {

    this.toastService.showToast({
      content: 'Backend error: ' + error.errorMessage,
      type: SiToastTypes.DANGER
    });
  }



  constructor(private toastService: SiNewtonToastService) { }
}
