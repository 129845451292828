<div class="chk-block-content">
  <canvas baseChart
  [datasets]="barChartData"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [legend]="barChartLegend"
  [chartType]="barChartType"
  [colors]="barChartColors">
  </canvas>
</div>
