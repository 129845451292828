import { Component, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { environment } from '../../../environments/environment';
import { HwCheckOverviewComponent } from './hw-check-overview/hw-check-overview.component';

@Component({
  selector: 'app-hw-check-overview-modal',
  templateUrl: './hw-check-overview-modal.component.html',
  styleUrls: ['./hw-check-overview-modal.component.scss']
})
export class HwCheckOverviewModalComponent {

  userRole!: string;

  contractName!: string;
  contractId!: number;
  quantityOfStatus!: any;

  @ViewChild(HwCheckOverviewComponent) hwCheckDiagram!: HwCheckOverviewComponent;

  getHwCheckData(){
    this.hwCheckDiagram.getHwCheckData();
  }

  downloadReport(){
    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/download-hw-report?contractId=' + this.contractId;

    const options = {
      observe: 'body' as const,
      responseType: 'blob' as const
    };

    this.httpService.get(endpoint, options).subscribe((blob: any) => {

      this.loadingSpinner.stopLoading();
      saveAs(blob, this.contractName + '-hw-comp-check.xlsx');

    });
  }
  constructor(public modalRef: BsModalRef, private httpService: HttpClient, private loadingSpinner: SiNewtonLoadingService){}

}
