  <div class="modal-header">
    <h4 class="modal-title pull-left">Overview of HW products in {{contractName}}</h4>
  </div>
  <div class="modal-body">

    <tabset>
      <tab heading="Availability overview" (selectTab)="getHwCheckData()">

        <app-hw-check-overview [quantityOfStatus]="quantityOfStatus" [contractId]="contractId"></app-hw-check-overview>
      </tab>
      <tab heading="Edit Measures" *ngIf="userRole == 'admin' || userRole == 'user'">
        <app-edit-measeurements [contractId]="contractId"></app-edit-measeurements>
      </tab>
    </tabset>

  </div>
  <div class="modal-footer">
    <button *ngIf="userRole == 'admin' || userRole == 'user'" type="button" class="btn btn-secondary" (click)="downloadReport()">Download report</button>
    <button type="button" class="btn btn-success" (click)="modalRef.hide()">Close</button>
  </div>


