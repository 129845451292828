import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadBomComponent } from '../../modals/upload-bom/upload-bom.component';
import { environment } from '../../../environments/environment';
import { CustomToastService } from '../../services/custom-toast.service';
import { ContractModel } from '../../data-models/contract';
import { UpdateContractModalComponent } from 'src/app/modals/update-contract-modal/update-contract-modal.component';
import { CustomHttpService } from 'src/app/services/custom-http.service';
import { DeleteContractModalComponent } from 'src/app/modals/delete-contract-modal/delete-contract-modal.component';
import { FunctionalRequirementsComponent } from '../functional-requirements/functional-requirements.component';
import { ManageReleaseDocumentsComponent } from 'src/app/modals/manage-release-documents/manage-release-documents.component';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {

  @Input()
  contracts: Array<ContractModel> = [];
  modalRef!: BsModalRef;
  documentFile!: File;

  @ViewChild('ContractOverview') contractOverview!: ElementRef;
  @ViewChild(FunctionalRequirementsComponent) functionRequirements!: FunctionalRequirementsComponent;

  @Input()
  userRole!: string;

  @Output() openHwCheckDetailsEvent = new EventEmitter();
  @Output() openUpgradeTimesEvent = new EventEmitter();
  @Output() openAdditionalDataEvent = new EventEmitter();

  @Output() getAllContractsEvent = new EventEmitter();

  openHwCheckDetails(contractRow: ContractModel) {
    this.openHwCheckDetailsEvent.emit(contractRow);
  }

  openUpgradeTimes(contractRow: ContractModel){
    this.openUpgradeTimesEvent.emit(contractRow);
  }

  openAdditionalData(contractRow: ContractModel){
    this.openAdditionalDataEvent.emit(contractRow);
  }
  getAllContracts(){
    this.getAllContractsEvent.emit();
  }


  public uploadBomModal(contractId: number, contractName: string){

    const initialState = {
      contractId,
      contractName
    };
    this.modalRef = this.modalService.show(UploadBomComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe((res: any) => {
      this.getAllContracts();
    })

  }


  public openDeleteContractModal(contractId: number, contractName: string){

    const initialState = {
      contractId,
      contractName
    };
    this.modalRef = this.modalService.show(DeleteContractModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe((res: any) => {
      this.getAllContracts();
    })

  }

  public deleteContract(contractId: number){
    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/delete-contract';

    const options = {
      responseType: 'json' as const
    };

    const formData = {
      contractId
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

        this.toastService.showInfoToast(response.message);

        this.getAllContracts();
    })
  }



  uploadReleaseDocument(contractId: number, e: Event, contractName: string){
    this.loadingSpinner.startLoading();

    console.log(e);

    this.documentFile = (e.target as HTMLInputElement).files!.item(0)!;

    const endpoint = environment.baseUrl + `/contract-management/upload-release-document`;

    const formData: FormData = new FormData();

    formData.append('contractId', contractId.toString());
    formData.append('document', this.documentFile);
    formData.append('fileName', contractId + '_' + this.documentFile.name);
    formData.append('contractName', contractName);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe( (res: any) => {
      this.loadingSpinner.stopLoading();
      this.toastService.showInfoToast(res.message);
    }, (error: HttpErrorResponse) => {
      this.loadingSpinner.stopLoading();


      this.toastService.showErrorToast(error.error.errorMessage);
    })

  }

  async downloadReleaseDocument(fileName: string) {

    await this.customHttpService.downloadFile(fileName, 'release-documents');

  }

  public openContractDataModel(contractId: number, contractName: string){
    const initialState = {
      contractName,
      contractId
    };

    this.modalRef = this.modalService.show(UpdateContractModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe((res: any) => {

      this.loadingSpinner.startLoading();

      (async () => {
        await new Promise( resolve => setTimeout(resolve, 1000) );

        this.getAllContracts();

        this.loadingSpinner.stopLoading();
    })();

    })
  }

  public openReleaseDocumentsModal(contractId: number, contractName: string){
    const initialState = {
      contractName,
      contractId,
      userRole: this.userRole
    };

    this.modalRef = this.modalService.show(ManageReleaseDocumentsComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe((res: any) => {

      this.loadingSpinner.startLoading();

      (async () => {
        await new Promise( resolve => setTimeout(resolve, 1000) );

        this.getAllContracts();

        this.loadingSpinner.stopLoading();
    })();

    })
  }

  public changeLcsAndLoaStatus(contractId: number, status: boolean, statusType: string){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + `/contract-management/change-${statusType}-status`;

    const formData = {
      contractId,
      status
    };

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe( (res: any) => {
      this.contracts = res;
    }, error => {
      this.toastService.showErrorToast(error.error.errorMessage);
    })

    this.loadingSpinner.stopLoading();
  }

  constructor(private httpService: HttpClient, private modalService: BsModalService, private customHttpService: CustomHttpService,
    private loadingSpinner: SiNewtonLoadingService, private toastService: CustomToastService) {
      console.log('Constructor Contract List');
    }

  ngOnInit(): void {
    console.log('ngOnInit Contract List');
    this.getAllContracts();
  }
}
