
 <si-newton-table [rows]="cricialComponents" [loading]="(cricialComponents) === null" [rowsPerPage]="15">

  <siNewtonTableColumn key="mlfb" name="MLFB">
    <ng-template siNewtonTableCell  let-value='value' let-row="row" width="">
      {{ row.mlfb }}
      <label title="{{row.description}}" style="float: right; margin: 1px; border: 0px; font-size: medium;">
        <i class="bi bi-info-circle-fill"></i>
      </label>
    </ng-template>
  </siNewtonTableColumn>

  <siNewtonTableColumn key="quantity" name="Qty." [widthFactor]="0.4" [disableFilter]="true">
    <div *siNewtonTableCell="let row = row">
      {{ row.quantity }}
    </div>
  </siNewtonTableColumn>

  <siNewtonTableColumn key="releaseStatus" name="Release Status">
    <div *siNewtonTableCell="let row = row" tooltip="Bla">
      {{ row.status }}
    </div>
  </siNewtonTableColumn>

  <siNewtonTableColumn key="measureComment" name="Measure / Comment" [widthFactor]="2">
    <div *siNewtonTableCell="let row = row">
      <textarea style="width: 100%" [(ngModel)]="row.measure_comment" [ngModelOptions]="{standalone: true}"
      (change)="updateMeasureCommentForCriticalComponent(row.mlfb)"></textarea>
    </div>
  </siNewtonTableColumn>

  <siNewtonTableColumn key="measurePlaced" name="Measure placed" [disableFilter]="true" [widthFactor]="0.5">
    <div *siNewtonTableCell="let row = row">
      <input type="checkbox" [(ngModel)]="row.is_measure_placed" (change)="updateMeasurePlacedForCriticalComponent(row.mlfb)">
    </div>
  </siNewtonTableColumn>

  <siNewtonTableColumn key="isComponentNotRelevant" name="Not Relevant" [disableFilter]="true" [widthFactor]="0.5">
    <div *siNewtonTableCell="let row = row">
      <input type="checkbox" [(ngModel)]="row.is_not_relevant" (change)="updateRelevanceOfCriticalComponent(row.mlfb)">
    </div>
  </siNewtonTableColumn>
</si-newton-table>
