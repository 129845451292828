import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { environment } from '../../../environments/environment';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { take } from 'rxjs/operators';


export interface DropDownOption {
  key: number | string;
  value: number | string;
}
@Component({
  selector: 'app-contract-creation',
  templateUrl: './contract-creation.component.html',
  styleUrls: ['./contract-creation.component.scss']
})
export class ContractCreationComponent implements OnInit {

  contractCreationForm!: FormGroup;
  date: FormControl | undefined;

  formattedStartDate!: string;

  contractDurationValues: Array<DropDownOption>  = [];
  requestTypeValues: Array<DropDownOption>  = [{
    key: 'preliminary', value: 'Preliminary Request'
  },
  {
    key: 'request', value: 'Request'
  }];


  fillContractDurationDropdwon(){

    for(let i = 5; i <= 15; i++){
      this.contractDurationValues.push({
        key: i, value: i
      });
    }
  }


  getContractDurationValue(contractDurationValues: DropDownOption){
    return contractDurationValues.value.toString();
  }

  getRequestTypeValue(requestTypeValues: DropDownOption){
    return requestTypeValues.value.toString();
  }

  formatStartDate(){

    console.log('formatStartDate()');

    let startDate: Date = this.contractCreationForm.get("startDate")?.value;

    this.formattedStartDate = (startDate.getDate() < 10 ?  '0' + startDate.getDate().toString() : startDate.getDate()) + '.' +
    ((startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)).toString() + '.' +
    (startDate.getFullYear());
  }

  calculateEndDate(){

    let contractDuration = this.contractCreationForm.get("contractDuration")?.value.value;

    if(this.formattedStartDate === null || ''){
      return;
    }

    console.log(this.formattedStartDate);
    let endDate = this.formattedStartDate.substr(0,6) + (parseInt(this.formattedStartDate.substr(6)) + contractDuration).toString();


    this.contractCreationForm.get('endDate')?.setValue(endDate);

  }

  async createContract() {

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/create-new-contract';
    const formData: FormData = new FormData();

    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();

    formData.append('contactPerson', this.contractCreationForm.get('contactPerson')?.value);
    formData.append('contactDepartment', this.contractCreationForm.get('contactDepartment')?.value);
    formData.append('contractName', this.contractCreationForm.get('contractName')?.value);
    formData.append('contractNumber', this.contractCreationForm.get('contractNumber')?.value);
    formData.append('customer', this.contractCreationForm.get('customer')?.value);
    formData.append('startDate', this.formattedStartDate);
    formData.append('endDate', this.contractCreationForm.get('endDate')?.value);
    formData.append('contractOwner', userMail);
    formData.append('requestType', this.contractCreationForm.get('requestType')?.value.key);


    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

        this.toastService.showInfoToast(response.message);
    })
  }

  constructor(private formBuilder: FormBuilder, private httpService: HttpClient,
    private loadingSpinner: SiNewtonLoadingService, private toastService: CustomToastService,
    private userManagementService: UserManagementService) {

  }

  ngOnInit() {

    this.fillContractDurationDropdwon();

    this.contractCreationForm = this.formBuilder.group({
      contactPerson: [null, Validators.required],
      contactDepartment: [null, Validators.required],
      contractName: [null, Validators.required],
      contractNumber: [null, Validators.required],
      customer: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
      contractDuration: [null, Validators.required],
      requestType: [null, Validators.required]
  });

    this.formattedStartDate = '';

  }
}
