
  <div class="col-md-10" style="margin: auto">

  <tabset>
    <tab heading="Contracts" id="contracts"  *ngIf="userRole == 'admin' || userRole == 'user'"
      (selectTab)="getAllContracts()">
      <div>
        <tabset>

          <tab *ngIf="userRole == 'admin' || userRole == 'user'" heading="Preliminary">
            <app-contract-list [contracts]="preliminaryRequests" [userRole]="userRole"  (openHwCheckDetailsEvent)="openHwCheckDetails($event)"
            (openUpgradeTimesEvent)="openUpgradeTimes($event)" (openAdditionalDataEvent)="openAdditionalData($event)"
            (getAllContractsEvent)="getAllContracts()"></app-contract-list>
          </tab>
          <tab heading="Contracts">
            <app-contract-list [contracts]="contracts" [userRole]="userRole"  (openHwCheckDetailsEvent)="openHwCheckDetails($event)"
            (openUpgradeTimesEvent)="openUpgradeTimes($event)" (openAdditionalDataEvent)="openAdditionalData($event)"
            (getAllContractsEvent)="getAllContracts()"></app-contract-list>
          </tab>
        </tabset>

      </div>
    </tab>


    <tab heading="Management View" *ngIf="userRole == 'admin' || userRole == 'orga'" (selectTab)="getAllContracts();refreshFunctionMatrix()">

      <app-managementview [contracts]="contracts"
        (openHwCheckDetailsEvent)="openHwCheckDetails($event)" (getAllContractsEvent)="getAllContracts()"
        (openUpgradeTimesEvent)="openUpgradeTimes($event)" (openAdditionalDataEvent)="openAdditionalData($event)"></app-managementview>

    </tab>

    <tab heading="MLFB View"  *ngIf="userRole == 'admin'">
      <app-mlfb-view></app-mlfb-view>
    </tab>

    <tab heading="Create contract" id="new_contract" *ngIf="userRole == 'admin' || userRole == 'user'">

      <app-contract-creation></app-contract-creation>

    </tab>
  </tabset>

  </div>

