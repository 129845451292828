//import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
//import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { environment } from '../../environments/environment';
import { ContractListComponent } from './contract-list/contract-list.component';
import { UserManagementService } from '../services/user-management.service';
import { ContractModel } from '../data-models/contract';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomToastService } from '../services/custom-toast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HwCheckOverviewModalComponent } from '../modals/hw-check-overview-modal/hw-check-overview-modal.component';
import { UpgradeTimesModalComponent } from '../modals/upgrade-times-modal/upgrade-times-modal.component';
import { AdditionalDataModalComponent } from '../modals/additional-data-modal/additional-data-modal.component';
import { ManagementviewComponent } from './managementview/managementview.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-tab-list',
  templateUrl: './dashboard-tab-list.component.html',
  styleUrls: ['./dashboard-tab-list.component.scss']
})
export class DashboardTabListComponent implements OnInit {

  @ViewChild(ContractListComponent) contractList!: ContractListComponent;
  @ViewChild(ManagementviewComponent) managementview!: ManagementviewComponent;


  contractOverviewImage!: any;
  functionalRequirementsImage!: any;

  contracts: Array<ContractModel> = [];
  preliminaryRequests: Array<ContractModel> = [];


  userRole!: string;
  modalRef!: BsModalRef;

  constructor(private userManagementService: UserManagementService, private loadingSpinner: SiNewtonLoadingService,
    private httpService: HttpClient, private toastService: CustomToastService, private modalService: BsModalService) {
  }

  async ngOnInit(): Promise<void> {
    this.userRole = await this.userManagementService.getUserRole().pipe(take(1)).toPromise();
  }

  public openHwCheckDetails(contractRow: ContractModel){

    const contractName = contractRow.contract_name;
    const contractId = contractRow.contract_id;

    const initialState = {
      contractName,
      contractId,
      userRole: this.userRole
    }
    this.modalRef = this.modalService.show(HwCheckOverviewModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(() => {  
      this.loadingSpinner.startLoading();
      this.getAllContracts();
      this.loadingSpinner.stopLoading();
    })
    // this.loadingSpinner.startLoading();

    // const contractName = contractRow.contract_name;
    // const contractId = contractRow.contract_id;

    // const formData: FormData = new FormData();
    // formData.append('contractId', contractId.toString());

    // const endpoint = environment.baseUrl + '/hw-check/create-report';

    // const options = {
    //   observe: 'body' as const,
    //   responseType: 'json' as const
    // };

    // this.httpService.post(endpoint, formData, options).subscribe((res: any) => {

    //   this.contracts = res.contracts;

    //   this.loadingSpinner.stopLoading();

    //   const initialState = {
    //     contractName,
    //     contractId,
    //     quantityOfStatus: res.checkResult,
    //     userRole: this.userRole
    //   };

    //   this.modalRef = this.modalService.show(HwCheckOverviewModalComponent, { initialState });
    //   this.modalRef.content.closeBtnName = 'Close';
    // }, error => {
    //   this.toastService.showErrorToast(error.error.errorMessage);
    // });

  }

  public openUpgradeTimes(contractRow: ContractModel){

    const contractId = contractRow.contract_id;
    const contractName = contractRow.contract_name;
    const lcsStartDate = contractRow.start_date;
    const lcsEndDate = contractRow.end_date;
    const contractNumber = contractRow.contract_number;
    const customer = contractRow.customer;
    const upgradePcsNeo = contractRow.upgrade_pcs_neo;
    const upgradeScheduling = contractRow.upgrade_scheduling;
    const secondUpgrade = contractRow.second_upgrade_needed;
    const thirdUpgrade = contractRow.third_upgrade_needed;
    const upgradeMonth = contractRow.upgrade_month;
    const upgradeCycle = contractRow.upgrade_cycle;
    const customFirstUpgradeYear = contractRow.custom_first_upgrade_year;


    const initialState = {
      contractName,
      contractId,
      lcsStartYear: parseInt(lcsStartDate.substr(6)),
      lcsEndYear: parseInt(lcsEndDate.substr(6)),
      contractNumber,
      customer,
      contractDuration: parseInt(lcsEndDate.substr(6)) - parseInt(lcsStartDate.substr(6)),
      upgradePcsNeo: parseInt(upgradePcsNeo),
      upgradeScheduling,
      upgradeMonth: parseInt(upgradeMonth),
      customFirstUpgradeYear: parseInt(customFirstUpgradeYear),
      upgradeCycle: parseInt(upgradeCycle),
      secondUpgradeChecked: secondUpgrade,
      thirdUpgradeChecked: thirdUpgrade,
      userRole: this.userRole
    };

    this.modalRef = this.modalService.show(UpgradeTimesModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe((res: any) => {

      this.loadingSpinner.startLoading();

      (async () => {
        await new Promise( resolve => setTimeout(resolve, 1000) );

        this.getAllContracts();

        this.loadingSpinner.stopLoading();
    })();

    })

  }

  public openAdditionalData(contractRow: ContractModel){

    const contractName = contractRow.contract_name;
    const contractId = contractRow.contract_id;

    const initialState = {
      contractName,
      contractId,
      userRole: this.userRole
    };

    this.modalRef = this.modalService.show(AdditionalDataModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(async (res: any) => {

      this.loadingSpinner.startLoading();

      const endpoint = environment.baseUrl +
        '/contract-management/update-status-additional-data';

      const formData = new FormData();

      const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();

      formData.append('contractId', contractId.toString());
      formData.append('userRole', this.userRole);
      formData.append('userMail', userMail);

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      }

      this.httpService.post(endpoint, formData, options).subscribe( (res: any) => {
        this.loadingSpinner.stopLoading();
        this.contracts = res;
      }, error => {

        this.loadingSpinner.stopLoading();
        this.toastService.showErrorToast(error.error.errorMessage);
      })
    })
  }

  public async getAllContracts(){

    this.loadingSpinner.startLoading();

    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();
    const userRole = await this.userManagementService.getUserRole().pipe(take(1)).toPromise();


    let endpoint = environment.baseUrl + '/contract-management/' +
      (
        userRole == 'user' ?
        'get-user-contracts?userMail=' + userMail :
        'get-all-contracts'
      );

    
    const options = {
      observe: 'body' as const,
      responseType: 'json' as const,
      // headers: {
      //   'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjYzalZZWW1wNG9VaEsyN3FfX3NnUSJ9.eyJodHRwczovL3NpZW1lbnMuY29tL2VtYWlsIjoiYmVuamFtaW4ua3JlaXNjaGVyQHNpZW1lbnMuY29tIiwiaHR0cHM6Ly9zaWVtZW5zLmNvbS9yb2xlcyI6WyJBZG1pbiIsIkxXU19BZG1pbiIsIlBDU25lbyBDb250cmFjdCBEYXNoYm9hcmQgQWRtaW4iXSwiaXNzIjoiaHR0cHM6Ly9zaWVtZW5zLXFhLTAwMTY5LmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJvYXV0aDJ8bWFpbi10ZW5hbnQtb2lkY3xzYW1scHxTaWVtZW5zfFowMDNYMlRSIiwiYXVkIjpbImxjcy1jb250cmFjdC1kYXNoYm9hcmQiLCJodHRwczovL3NpZW1lbnMtcWEtMDAxNjkuZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcwMjQ1OTQ4OSwiZXhwIjoxNzAyNTQ1ODg5LCJhenAiOiJZZUhobTNMOU5DZVE2Y3VZY0NCajdnTzNJdTdYR3g1VSIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.niIUoKWGDvZGP_fCSQiR-5W65dc0u7HQIhaqcvgfk7I0e57jthRQp--sqtE31CO0LiuAzTY9EkzDAp2njsMYsbqsrvh-oAWM7PxYiVbEVZMSGu4nk0w-N0UhpRL8j7C1p2ympEPbhJ6ASjQo004VayMBTETHn8vLh9AFNxE-VPxYTOWU0GZRtT5bO1065OB2BLBpyuLVJKcBAxsiWVJOAHx8A9dPucgnmPjn2ZMZlUHCK1mCcJGF8GHRU7rtFG5EAltLxyIlm6Uzd_vjsAq0dI-su3O5WSjs9N1Sxeo_cs1sPNk0abaagSmsuXIgkcPfvJjl3jwpDOyfXRAY7N1i3g'
      // }
    };

    this.httpService.get(endpoint, options).subscribe(
      (response: any) => {

        const allContracts = response.contracts as Array<ContractModel>;

        console.log(allContracts);
        this.contracts = allContracts.filter(element =>
          element.type_of_request == 'request');
        this.preliminaryRequests = allContracts.filter(element =>
          element.type_of_request == 'preliminary');

        this.loadingSpinner.stopLoading();

      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorToast('Could not fetch contracts.\n' + errorResponse.error.errorMessage);
        this.contracts = [];
        this.preliminaryRequests = [];
      });

  }

  async refreshFunctionMatrix(){
    await this.managementview.functionRequirements.fillUsedFunctionsIntoChart();
  }
}
