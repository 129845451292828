import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdditionalDataRestService {

  addEntry(contractId: number, entryType: string, entryFirstParameter: string, entrySecondParameter: string): Promise<object> {

    return new Promise( (resolve, reject) => {
      const endpoint = environment.baseUrl + `/additional-data/add-new-entry`;

      const formData: FormData = new FormData();

      formData.append('contractId', contractId.toString());
      formData.append('entryType', entryType.toString());
      formData.append('entryFirstParameter', entryFirstParameter);
      formData.append('entrySecondParameter', entrySecondParameter);

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };


      this.httpService.post(endpoint, formData, options).subscribe((res: any) => {

        resolve(res);
      }, (error: HttpErrorResponse) => {
        reject(error.error.errorMessage)
      })
    })
  }


  updateEntry(contractId: number, entryType: string, entryId: number, entryFirstParameter: string, entrySecondParameter: string): Promise<object> {

    return new Promise( (resolve, reject) => {
      const endpoint = environment.baseUrl + `/additional-data/update-entry`;

      const formData: FormData = new FormData();

      formData.append('contractId', contractId.toString());
      formData.append('entryId', entryId.toString());
      formData.append('entryType', entryType.toString());
      formData.append('entryName', entryFirstParameter);
      formData.append('entryComment', entrySecondParameter);

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };


      this.httpService.post(endpoint, formData, options).subscribe((res: any) => {

        resolve(res);
      }, (error: HttpErrorResponse) => {
        reject(error.error.errorMessage)
      })
    })
  }

  deleteEntry(contractId: number, entryType: string, entryId: number): Promise<object>{

    return new Promise( (resolve, reject) => {
      const endpoint = environment.baseUrl + `/additional-data/delete-entry`;

      const formData: FormData = new FormData();

      formData.append('contractId', contractId.toString());
      formData.append('entryId', entryId.toString());
      formData.append('entryType', entryType.toString());

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };


      this.httpService.post(endpoint, formData, options).subscribe((res: any) => {

        resolve(res);
      }, (error: HttpErrorResponse) => {
        reject(error.error.errorMessage)
      })
    })
  }

  updateEntryStatus(entryId: number, status: String): Promise<object | void> {
    return new Promise( (resolve, reject) => {
      const endpoint = environment.baseUrl + `/additional-data/update-entry-status`;

      const formData: FormData = new FormData();

      formData.append('entryId', entryId.toString());
      formData.append('status', status.toString());

      const options = {
        responseType: 'text' as const
      };


      this.httpService.post(endpoint, formData, options).subscribe((res: any) => {
        resolve();
      }, (error: HttpErrorResponse) => {
        reject(error.error.errorMessage)
      })
    })
  }

  uploadDocumentForEntry(entryId: number, document: File, contractName: string) : Promise<string> {

    return new Promise( (resolve, reject) => {
      const endpoint = environment.baseUrl + `/additional-data/upload-entry-document`;

      const formData: FormData = new FormData();

      formData.append('entryId', entryId.toString());
      formData.append('document', document);
      formData.append('fileName', entryId + '_' + document.name);
      formData.append('contractName', contractName);

      const options = {
        responseType: 'json' as const
      };


      this.httpService.post(endpoint, formData, options).subscribe((res: any) => {
        resolve(res.message);
      }, (error: HttpErrorResponse) => {
        reject(error.error.errorMessage)
      })
    })
  }

  async getEntries(contractId: number, entryType: string): Promise<object> {


    return new Promise( (resolve, reject) => {

      const endpoint = environment.baseUrl + `/additional-data/get-entries?contractId=${contractId}&entryType=${entryType}`;

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };

      this.httpService.get(endpoint, options).subscribe((res: any) => {
        resolve(res);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        reject(error.error.errorMessage);
      })
    })
  }


  constructor(private httpService: HttpClient) { }
}
