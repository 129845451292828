import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { environment } from '../../environments/environment';
import { ConstData } from '../const-data/const-data';

interface FunctionInContract {
  contract_name: string, function_name: string, upgrade_pcs_neo_year: string
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  usedFunctions!: FunctionInContract[];


  constructor() { }

  async getUsedFunctions(): Promise<FunctionInContract[]>{

    try {
      const endpoint = environment.baseUrl + '/contract-overview/get-function-overview';

      const response = await fetch(endpoint);

      const usedFunctions = await response.json();

      return usedFunctions;

    } catch {
      return [{contract_name: '', function_name: '', upgrade_pcs_neo_year: ''}];
    }

  }

  async createTableOfDetailedFunctionOverview(pdfReport: jsPDF, insertHeight: number): Promise<jsPDF>{

    let allFunctions = ConstData.getUsableFunctions();

    this.usedFunctions = await this.getUsedFunctions();

    console.log(this.usedFunctions);

    for(let functionName of allFunctions){

      let usedFunctionsOfCurrentFunction =
        this.usedFunctions.filter(usedFunction =>
          usedFunction.function_name.replace(/ /g, '').toLocaleLowerCase() == functionName.replace(/ /g, '').toLocaleLowerCase())
        .sort((a, b) =>  a.contract_name < b.contract_name ? -1 : 1);

      if(usedFunctionsOfCurrentFunction.length < 1){
        continue;
      }

      let jahresZahlen = [];

      for (let i = 2022; i < 2040; i++) {
        jahresZahlen.push(i.toString());
      }

      //let templateRowForTableData = new Array<string>(jahresZahlen.length).fill(' ');

      let dataBodyForTable = [];
      dataBodyForTable.push(new Array<string>(jahresZahlen.length).fill(' '));

      console.log(dataBodyForTable);
      for(let usedFunction of usedFunctionsOfCurrentFunction){

        console.log(usedFunction);

        const fixLength = dataBodyForTable.length;

        for(let i = 0; i <= fixLength; i++){

          if(dataBodyForTable[i][jahresZahlen.indexOf(usedFunction.upgrade_pcs_neo_year)] == ' '){
            dataBodyForTable[i][jahresZahlen.indexOf(usedFunction.upgrade_pcs_neo_year)]  = usedFunction.contract_name;
            break;
          } else {

            if(i == dataBodyForTable.length - 1){
              dataBodyForTable.push(new Array<string>(jahresZahlen.length).fill(' '));
            }
          }
        }
      }

      console.log(dataBodyForTable);

      pdfReport.setFont("helvetica");
      pdfReport.setFontSize(18);
      pdfReport.text(functionName, 30, insertHeight);

      autoTable(pdfReport, {
        startY: insertHeight + 25,
        headStyles: { fontSize: 16, fontStyle: 'bold' },
        bodyStyles: { fontSize: 16 },
        head: [jahresZahlen],
        body: dataBodyForTable
      })

      insertHeight = (pdfReport as any).lastAutoTable.finalY + 15;
    }

    return pdfReport;
  }


}
