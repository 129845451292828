import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { Color } from 'ng2-charts';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-hw-check-overview',
  templateUrl: './hw-check-overview.component.html',
  styleUrls: ['./hw-check-overview.component.scss']
})
export class HwCheckOverviewComponent implements OnInit {


  @Input()
  quantityOfStatus!: any;

  @Input()
  contractId!: any;

  public barChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
    }
  };

  public barChartLabels:string[] = [ 'Not included', 'Release ok', 'Limited release', 'Decision open', 'No release', 'Measure placed', 'Not relevant'];

  public barChartType:any = 'bar';
  public barChartLegend:boolean = false;
  public barChartData:any[] = [];

  public barChartColors: Color[] = [
    { backgroundColor: 'gray' }
  ];

  public getHwCheckData(){

    this.loadingSpinner.startLoading();

    const formData: FormData = new FormData();
    formData.append('contractId', this.contractId.toString());

    const endpoint = environment.baseUrl + '/hw-check/create-report';

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((res: any) => {

      this.quantityOfStatus = res.checkResult;

      this.adaptChartData();

      this.loadingSpinner.stopLoading();

    }, error => {
      this.toastService.showErrorToast(error.error.errorMessage);
    });

  }

  adaptChartData(){
    this.barChartData = [
      {data:
        [
          this.quantityOfStatus.notIncluded,
          this.quantityOfStatus.releaseOk,
          this.quantityOfStatus.limitedRelease,
          this.quantityOfStatus.decisionOpen,
          this.quantityOfStatus.noRelease,
          this.quantityOfStatus.measurePlaced,
          this.quantityOfStatus.notRelevant
        ]}
    ];
  }

  constructor(private loadingSpinner: SiNewtonLoadingService, private httpService: HttpClient,
    private toastService: CustomToastService) { }

  ngOnInit(){

    this.getHwCheckData();
  }
}
