import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReleaseDocument } from 'src/app/data-models/releaseDocument';
import { FileTransferService } from 'src/app/services/file-transfer.service';
import { environment } from '../../../environments/environment';


export interface DropDownOption {
  key: number | string;
  value: number | string;
}

@Component({
  selector: 'app-manage-release-documents',
  templateUrl: './manage-release-documents.component.html',
  styleUrls: ['./manage-release-documents.component.scss']
})
export class ManageReleaseDocumentsComponent implements OnInit {

  contractId!: number;
  contractName!: string;
  userRole!: string;

  releaseDocumentFile!: File;

  releaseDocuments: Array<ReleaseDocument> = [];
  hwReleaseDocuments: Array<ReleaseDocument> = [];
  swReleaseDocuments: Array<ReleaseDocument> = [];
  addonsReleaseDocuments: Array<ReleaseDocument> = [];
  otherReleaseDocuments: Array<ReleaseDocument> = [];



  @ViewChild('documentSelection', { static: true })
  releaseDocumentFileInput!: ElementRef<HTMLInputElement>;


  typesOfReleaseDocuments = [
    {key: 'hwCompCheck', value: 'HW Compatibility Check'},
    {key: 'swCompCheck', value: 'SW Compatibility Check'},
    {key: 'addonsLibraries', value: 'Addons / Libraries'},
    {key: 'other', value: 'Other Documents'}
  ];

  selecteReleaseDocType: string;
background: any;

  public getTypeOfReleaseDocumentValue(dropdownObject: DropDownOption){
    return dropdownObject.value.toString();
  }

  constructor(public modalRef: BsModalRef, private fileTransferService: FileTransferService, private httpService: HttpClient) {
    this.selecteReleaseDocType = '';
  }

  ngOnInit(): void {
    this.getReleaseDocuments();
  }

  async getReleaseDocuments(){

      const endpoint = environment.baseUrl + `/file-management/get-release-documents?contractId=${this.contractId}`;

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };

      this.httpService.get(endpoint, options).subscribe((res: any) => {
        this.releaseDocuments = (res.documents as ReleaseDocument[]);
        this.hwReleaseDocuments = this.releaseDocuments.filter(element => element.documentType == 'hwCompCheck');
        this.swReleaseDocuments = this.releaseDocuments.filter(element => element.documentType == 'swCompCheck');
        this.addonsReleaseDocuments = this.releaseDocuments.filter(element => element.documentType == 'addonsLibraries');
        this.otherReleaseDocuments = this.releaseDocuments.filter(element => element.documentType == 'other');

      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.releaseDocuments = [];
      })

  }

  onSelect(event: Event): void {
    const selectedElement = event.target as HTMLSelectElement;

    if (selectedElement && selectedElement.value) {
      this.selecteReleaseDocType = this.typesOfReleaseDocuments.find(option => option.key === selectedElement.value)?.key || '';
      console.log(this.selecteReleaseDocType);
    }
  }

  async storeFile(){

    this.releaseDocumentFile = this.releaseDocumentFileInput!.nativeElement!.files!.item(0)!;

    console.log(this.releaseDocumentFile.name);
  }


  async uploadReleaseDocument(){
    await this.fileTransferService.uploadReleaseDocumentFile(this.contractId, this.releaseDocumentFile.name, this.selecteReleaseDocType, this.releaseDocumentFile);

    this.getReleaseDocuments();
  }

  async downloadFile(internalFileName: string, visibleFileName: string){
    await this.fileTransferService.downloadReleaseDocumentFile(internalFileName, visibleFileName);

  }

  async deleteFile(documentId: number){
    await this.fileTransferService.deleteReleaseDocumentFile(documentId);

    this.getReleaseDocuments();
  }
}
