<div class="modal-header">
  <h4 class="modal-title pull-left">
    Update contract {{contractName}}
  </h4>
</div>
<div #modalBody class="modal-body">

    <form [formGroup]="contractForm" class="col-md-16" style="align-items: center; margin-top: 0px;">

      <div class="row">

        <div class="col">

          <si-newton-form-group label="Select type of request">
            <si-newton-dropdown
                  name="requestType"
                  [dropdownOptions]="requestTypeValues"
                  [valueProvider]="getReportTypeValue"
                  formControlName="requestType"
                >
            </si-newton-dropdown>
          </si-newton-form-group>
        </div>

        <div class="col">

        </div>


      </div>

      <div class="row">

        <div class="col">
          <si-newton-form-group label="Contract name" [errorResolverMap]="{required: 'Value is required'}">
            <input id="contractName" formControlName="contractName"
              siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Contract Number" [errorResolverMap]="{required: 'Value is required'}">
            <input id="contractNumber" formControlName="contractNumber"
              siNewton>
          </si-newton-form-group>
        </div>


      </div>


      <div class="row">
        <div class="col">
          <si-newton-form-group label="Customer" [errorResolverMap]="{required: 'Value is required'}">
            <input id="customer" formControlName="customer"
              siNewton>
          </si-newton-form-group>

        </div>

        <div class="col">
          <si-newton-form-group label="Contact person (full name)" [errorResolverMap]="{required: 'Value is required'}">
            <input id="contactPerson" formControlName="contactPerson"
              siNewton>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">

        <div class="col">
          <si-newton-form-group label="Contact Department" [errorResolverMap]="{required: 'Value is required'}">
            <input id="contactDepartment" formControlName="contactDepartment"
              siNewton>
          </si-newton-form-group>
        </div>

        <div class="col"></div>
      </div>

      <div class="row">

        <div class="col">
          <si-newton-form-group label="Contract Start" [errorResolverMap]="{required: 'Value is required'}" >
            <input id="startDate" formControlName="startDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY' }"
            siNewton (ngModelChange)="formatStartDate();calculateEndDate()" readonly="true" style="background-color: white !important;">
          </si-newton-form-group>
        </div>

        <div class="col">

          <si-newton-form-group label="Select duration of contract in years">
            <si-newton-dropdown
                  name="contractDuration"
                  [dropdownOptions]="contractDurationValues"
                  [valueProvider]="getContractDurationValue"
                  formControlName="contractDuration"
                  (ngModelChange)="calculateEndDate()"
                >
            </si-newton-dropdown>
          </si-newton-form-group>
        </div>



      </div>

      <div class="row">
        <div class="col">
          <si-newton-form-group label="Contract End" [errorResolverMap]="{required: 'Value is required'}">
            <input id="endDate" formControlName="endDate" readonly="true"
              siNewton>
          </si-newton-form-group>
        </div>

        <div class="col"></div>
      </div>

      <div class="row">
        <div class="col">
          <si-newton-form-group label="Contract Created by" [errorResolverMap]="{required: 'Value is required'}">
            <input id="contractOwner" formControlName="contractOwner" readonly="true"
              siNewton>
          </si-newton-form-group>
        </div>

        <div class="col"></div>
      </div>

    </form>


  <div class="modal-footer">
    <button type="button" class="btn btn-success col-md-3" style="float: right;"
    (click)="updateContract()" [disabled]="!(contractForm.valid)">Update contract data</button>
  </div>
</div>

