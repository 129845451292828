import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { environment } from '../../environments/environment';
import { CustomToastService } from './custom-toast.service';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {

  defaultHttpPostObserveBodyJson(endpoint: string, formData: FormData){

    return new Promise( (resolve, reject) => {

      this.httpService.post(environment.baseUrl + endpoint, formData, {
        observe: 'body' as const,
        responseType: 'json' as const
      }).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();
        this.toastService.showInfoToast(response.message);

        resolve(true);

      }, (errorResponse: HttpErrorResponse) => {

        this.loadingSpinner.stopLoading();
        console.log(errorResponse);
        this.toastService.showErrorToast(errorResponse.error.errorMessage);

        reject(false);
      })

    })
  }


  downloadFile(fileName: string, bucketName: string){
    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + `/file-management/download-document?fileName=${fileName}&fileBucket=${bucketName}`;

    const options = {
      observe: 'body' as const,
      responseType: 'blob' as const
    };

    this.httpService.get(endpoint, options).subscribe(
      async blob => {
        this.loadingSpinner.stopLoading();
        saveAs(blob, fileName);

      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();

        this.toastService.showErrorToast('Could no download the file');
      });
  }

  constructor(private loadingSpinner: SiNewtonLoadingService, private httpService: HttpClient,
    private toastService: CustomToastService) { }
}
