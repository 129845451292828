<div *ngIf="userRole == 'user' || userRole == 'admin'" class="row" style="margin-top: 3em;">

  <div class="col-md-12">
    <input #auditResultImport class="form-check-input" type="checkbox" [(ngModel)]="showImportOptions">
    <label for="auditResultImport" class="form-check-label">
      Import selected Software functions via Audit result file
    </label>
  </div>
</div>

<div class="row">
  <div class="col-md-3">
    <label class="btn btn-primary" [ngStyle]="{'width': '100%', 'visibility': showImportOptions ? 'visible' : 'hidden'}">
      Select File
      <span class="newton-search"></span>
      <input #auditFileSelection type="file"
        accept="application/msexcel"
        (change)="storeFile()"
        >
    </label>
  </div>

  <div class="col-md-3">
    <button type="button" [disabled]="auditFile == undefined"
    class="btn btn-success"
    [ngStyle]="{'width': '100%', 'visibility': showImportOptions ? 'visible' : 'hidden'}"
    (click)="importAuditFile()"
  >
    Upload Audit result
  </button>
  </div>
</div>


  <div class="row" style="margin-top: 2em;">
    <div style="text-align: right; font-weight: 400; width: 100%;">
      <img
        src="/assets/green_circle.png"
        width="20"
        height="20"
      /> : Available
      <img
        src="/assets/yellow_circle.png"
        width="20"
        height="20"
      /> : Upgrade Month to be adapted
      <img
        src="/assets/red_circle.png"
        width="20"
        height="20"
      /> : Not available
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">

<si-newton-table [rows]="functions" [loading]="(functions) === null" [rowsPerPage]="50">

  <siNewtonTableColumn key="function_name" name="Function"></siNewtonTableColumn>
  <siNewtonTableColumn key="customer_request" name="Customer request" [disableFilter]="true"
  [disableSort]="true" [widthFactor]='0.3'>

    <ng-template siNewtonTableCell let-value="value" let-row="row">
      <input #thirdUpgradeCb class="form-check-input" type="checkbox" [disabled]="userRole == 'orga'"
    [(ngModel)]="row.customer_request"
    (click)="updateContractFuntion(row.function_name, row.customer_request)">
    </ng-template>


  </siNewtonTableColumn>
  <siNewtonTableColumn key="status" name="Available" [disableFilter]="true"
  [disableSort]="true" [widthFactor]='0.3'>
    <ng-template siNewtonTableCell let-value="value" let-row="row">
      <img
        *ngIf="checkAivalability(row.release_month, row.release_year) === 'available' && row.customer_request == true"
        src="/assets/green_circle.png"
        width="20"
        height="20"
      />

      <img
      *ngIf="checkAivalability(row.release_month, row.release_year) === 'not available' && row.customer_request == true"
        src="/assets/red_circle.png"
        width="20"
        height="20"
      />

      <img
      *ngIf="checkAivalability(row.release_month, row.release_year) === 'critical' && row.customer_request == true"
        src="/assets/yellow_circle.png"
        width="20"
        height="20"
      />
    </ng-template>

  </siNewtonTableColumn>


  <div no-data>
    Loading ...
  </div>

</si-newton-table>
</div></div>
