import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomHttpService } from 'src/app/services/custom-http.service';

@Component({
  selector: 'app-upload-bom',
  templateUrl: './upload-bom.component.html',
  styleUrls: ['./upload-bom.component.scss']
})
export class UploadBomComponent {

  title: string | undefined;
  closeBtnName: string | undefined;
  contractId!: number;
  bomUploadForm: FormGroup;
  contractName!: string;

  @ViewChild('bomFileSelection', { static: true })
  bomFileSelection: ElementRef<HTMLInputElement> | undefined;

  bomFile: File | undefined;


  constructor(public modalRef: BsModalRef, private customHttpService: CustomHttpService,
    private formBuilder: FormBuilder, private loadingSpinner: SiNewtonLoadingService) {
    this.bomUploadForm = this.formBuilder.group({
      bomFileSelection: [null, Validators.required]
    });
  }

  async storeBomFile(){

    this.bomFile = this.bomFileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async uploadBom(){

    this.loadingSpinner.startLoading();

    const formData: FormData = new FormData();
    formData.append('bomFile', this.bomFile!);
    formData.append('contractId', this.contractId.toString());
    formData.append('contractName', this.contractName);

    await this.customHttpService.defaultHttpPostObserveBodyJson('/contract-management/upload-bom', formData);

    this.loadingSpinner.stopLoading();
  }

}
