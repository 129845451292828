import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardTabListComponent } from './dashboard-tab-list/dashboard-tab-list.component';
import { HwReferenceChangeManagementComponent } from './pages/hw-reference-change-management/hw-reference-change-management.component';

import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    data: {
      title: 'Welcome'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'hw-reference-change-management',
    component: HwReferenceChangeManagementComponent,
    data: {
      title: 'HW Reference'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'contracts',
    component: DashboardTabListComponent,
    data: {
      title: 'Contracts'
    },
    canActivate: [AuthGuard]
  },
  {
    path: '*',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
