import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-additional-data-modal',
  templateUrl: './additional-data-modal.component.html',
  styleUrls: ['./additional-data-modal.component.scss']
})
export class AdditionalDataModalComponent implements OnInit {

  userRole!: string;

  contractName!: string;
  contractId!: number;

  constructor(public modalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

}
