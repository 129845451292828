<div class="modal-header">
  <h4 class="modal-title pull-left">
    Release Documents
  </h4>
</div>
<div #modalBody class="modal-body">

  <div [hidden]="userRole != 'admin'">
    <div class="row">
      <h4 style="padding-left: 10px">Upload of new Documents</h4>
    </div>

    <div class="row" style="padding-left: 10px">
      <div style="vertical-align: center">
        <label for="releaseDocType">Select Type of document: </label>
        <select id="releaseDocType" [(ngModel)]="selecteReleaseDocType" (change)="onSelect($event)">
          <option *ngFor="let option of typesOfReleaseDocuments" [value]="option.key">
            {{ option.value }}
          </option>
        </select>

      </div>
    </div>

    <div class="row" style="padding-left: 10px">

      <div>
        <label class="btn btn-primary" style="float: left; margin: 5px;">
          Select Release Document
          <span class="newton-search"></span>
          <input #documentSelection type="file"
            accept="application/msexcel"
            (change)="storeFile()"
          >
        </label>
      </div>

      <div>
        <button type="button" class="btn btn-success" style="float: right; margin: 5px;"
        [disabled]="selecteReleaseDocType == '' || !releaseDocumentFile" (click)="uploadReleaseDocument()">Upload</button>

      </div>


    </div>
  </div>


  <div class="row">
    <h4 style="padding-left: 10px">HW Compatibility Check</h4>
  </div>
  <div *ngIf="hwReleaseDocuments.length == 0">
    <div class="row">
      No Documents uploaded yet
    </div>
  </div>
  <div *ngFor="let releaseDocument of hwReleaseDocuments; let i = index">
    <div class="row">

      <div>
        <div class="file-name">{{ releaseDocument.fileName }}
          <button title="Download" class="btn btn-success" style="margin: 1px; border: 0px;"
          (click)="downloadFile(releaseDocument.internalFileName, releaseDocument.fileName)">
          <i class="bi bi-download"></i>
          </button>
          <button *ngIf="userRole == 'admin'" title="Delete" class="btn btn-warning" style="margin: 1px; border: 0px;"
          (click)="deleteFile(releaseDocument.id)">
          <i class="bi bi-trash"></i>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <h4 style="padding-left: 10px">SW Compatibility Check</h4>
  </div>
   <div *ngIf="swReleaseDocuments.length == 0">
    <div class="row">
      No Documents uploaded yet
    </div>
  </div>
  <div *ngFor="let releaseDocument of swReleaseDocuments; let i = index">
    <div class="row">

      <div>
        <div class="file-name">{{ releaseDocument.fileName }}
          <button title="Download" class="btn btn-success" style="margin: 1px; border: 0px;"
          (click)="downloadFile(releaseDocument.internalFileName, releaseDocument.fileName)">
          <i class="bi bi-download"></i>
          </button>
          <button *ngIf="userRole == 'admin'" title="Delete" class="btn btn-warning" style="margin: 1px; border: 0px;"
          (click)="deleteFile(releaseDocument.id)">
          <i class="bi bi-trash"></i>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <h4 style="padding-left: 10px">Addons & Libraries</h4>
  </div>
  <div *ngIf="addonsReleaseDocuments.length == 0">
    <div class="row">
      No Documents uploaded yet
    </div>
  </div>
  <div *ngFor="let releaseDocument of addonsReleaseDocuments; let i = index">
    <div class="row">

      <div>
        <div class="file-name">{{ releaseDocument.fileName }}
          <button title="Download" class="btn btn-success" style="margin: 1px; border: 0px;"
          (click)="downloadFile(releaseDocument.internalFileName, releaseDocument.fileName)">
          <i class="bi bi-download"></i>
          </button>
          <button *ngIf="userRole == 'admin'" title="Delete" class="btn btn-warning" style="margin: 1px; border: 0px;"
          (click)="deleteFile(releaseDocument.id)">
          <i class="bi bi-trash"></i>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <h4 style="padding-left: 10px">Other</h4>
  </div>
  <div *ngIf="otherReleaseDocuments.length == 0">
    <div class="row">
      No Documents uploaded yet
    </div>
  </div>
  <div *ngFor="let releaseDocument of otherReleaseDocuments; let i = index">
    <div class="row">

      <div>
        <div class="file-name">{{ releaseDocument.fileName }}
          <button title="Download" class="btn btn-success" style="margin: 1px; border: 0px;"
          (click)="downloadFile(releaseDocument.internalFileName, releaseDocument.fileName)">
          <i class="bi bi-download"></i>
          </button>
          <button *ngIf="userRole == 'admin'" title="Delete" class="btn btn-warning" style="margin: 1px; border: 0px;"
          (click)="deleteFile(releaseDocument.id)">
          <i class="bi bi-trash"></i>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modalRef.hide()">Close</button>
  </div>
</div>
