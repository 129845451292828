export class ConstData {

  static getUsableFunctions(){
    return ['Advanced ES (FL)', 'BATCH', 'CFC', 'DOCPRO', 'F-System', 'IEA  (FL)', 'OS Engineering',
    'Maintenance ES  (FL)', 'Management Console  (SL)', 'OpenPCS 7 Station / OS  (SL)*', 'OS Client  (FL)', 'OS Engineering',
    'OS Redundancy ', 'OS Server ', 'OS Server Redundancy  (SL)', 'OS Single Station Basic  (SL)',
    'OS Web Server Basic V8.2 (SL)', 'PDM Basic V9.0 (FL)', 'Process Historian Archive - BATCH  (SL)',
    'Process Historian Server Redundancy  (SL)', 'Route Control Routes (CR: 10, SL)', 'S7-PLCSIM  (FL)', 'S7-SCL ',
    'Safety Matrix Editor  (SL)', 'SFC', 'SIMATIC Information Server  (SL)', 'SNMP-OPC-Server Basic  (SL)', 'SOFTNET-IE S7',
    'STEP 7', 'TH-PO', 'Version Cross Manager (FL)', 'Version Trail  (FL)'];
  }
}
