import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { environment } from '../../../environments/environment';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { ContractModel } from '../../data-models/contract';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserManagementService } from 'src/app/services/user-management.service';


export interface DropDownOption {
  key: number | string;
  value: number | string;
}
@Component({
  selector: 'app-update-contract-modal',
  templateUrl: './update-contract-modal.component.html',
  styleUrls: ['./update-contract-modal.component.scss']
})
export class UpdateContractModalComponent implements OnInit {

  contractId!: number;
  contractName!: string;
  contractForm!: FormGroup;
  date: FormControl | undefined;
  formattedStartDate!: string;

  userManagementService: UserManagementService;

  selectedContract!: ContractModel;

  contractDurationValues: Array<DropDownOption>  = [];
  requestTypeValues: Array<DropDownOption>  = [{
    key: 'preliminary', value: 'Premilinary Request'
  },
  {
    key: 'request', value: 'Request'
  }];


  fillContractDurationDropdwon(){

    for(let i = 5; i <= 15; i++){
      this.contractDurationValues.push({
        key: i, value: i
      });
    }
  }

  getContractDurationValue(contractDurationValues: DropDownOption){
    return contractDurationValues.value.toString();
  }

  getReportTypeValue(requestTypeValues: DropDownOption){
    return requestTypeValues.value.toString();
  }


  private setDropdownSelection(formControlName: string, selection: number | string) {
    this.contractForm.controls[formControlName].setValue({
      key: selection.toString(),
      value: selection.toString(),
    });
  }

  formatStartDate(){

    const valueOfStartDate = this.contractForm.get("startDate")?.value;

    if(valueOfStartDate.length === 10){

      this.formattedStartDate = valueOfStartDate;
      return;
    }

    let startDate: Date = valueOfStartDate;

    this.formattedStartDate = (startDate.getDate() < 10 ?  '0' + startDate.getDate().toString() : startDate.getDate()) + '.' +
    ((startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)).toString() + '.' +
    (startDate.getFullYear());
  }

  calculateEndDate(){

    if(this.formattedStartDate === null || '' || !this.contractForm.get("contractDuration")?.value){
      return;
    }

    let contractDuration = this.contractForm.get("contractDuration")?.value.value;

    let endDate = this.formattedStartDate.substr(0,6) + (parseInt(this.formattedStartDate.substr(6)) + parseInt(contractDuration)).toString();


    this.contractForm.get('endDate')?.setValue(endDate);
  }

  getContractData(){

    const endpoint = environment.baseUrl + '/contract-management/get-contract?contractId=' + this.contractId;

    const options = {
      responseType: 'json' as const
    };

    this.httpService.get(endpoint, options).subscribe((res: any) => {
      this.contractForm.get('contactPerson')?.setValue((res as ContractModel).contact_person);
      this.contractForm.get('contactDepartment')?.setValue((res as ContractModel).contact_department);
      this.contractForm.get('contractName')?.setValue((res as ContractModel).project_name);
      this.contractForm.get('contractNumber')?.setValue((res as ContractModel).contract_number);
      this.contractForm.get('customer')?.setValue((res as ContractModel).customer);
      this.contractForm.get('startDate')?.setValue((res as ContractModel).start_date);
      this.contractForm.get('endDate')?.setValue((res as ContractModel).end_date);
      this.contractForm.get('contractOwner')?.setValue((res as ContractModel).contract_owner);


      const contractDuration = parseInt((res as ContractModel).end_date.substr(6)) - parseInt((res as ContractModel).start_date.substr(6));

      this.setDropdownSelection('contractDuration', contractDuration);

      const requestTypeObject = this.requestTypeValues.filter(element =>
        element.key == (res as ContractModel).type_of_request);

      console.log(requestTypeObject);

      this.contractForm.controls['requestType'].setValue((requestTypeObject as any).key);

    })
  }

  async updateContract() {

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/update-contract';
    const formData: FormData = new FormData();

    formData.append('contactPerson', this.contractForm.get('contactPerson')?.value);
    formData.append('contactDepartment', this.contractForm.get('contactDepartment')?.value);
    formData.append('contractName', this.contractForm.get('contractName')?.value);
    formData.append('contractNumber', this.contractForm.get('contractNumber')?.value);
    formData.append('customer', this.contractForm.get('customer')?.value);

    formData.append('startDate', this.formattedStartDate);
    formData.append('endDate', this.contractForm.get('endDate')?.value);

    formData.append('contractId', this.contractId.toString());
    formData.append('requestType', this.contractForm.get('requestType')?.value.key);


    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

        this.toastService.showInfoToast(response.message);

        this.modalRef.hide()
    })
  }

  constructor(private modalRef: BsModalRef, private formBuilder: FormBuilder, private httpService: HttpClient,
    private loadingSpinner: SiNewtonLoadingService, private toastService: CustomToastService,
    userManagementService: UserManagementService) {
      this.userManagementService = userManagementService;
    }

  ngOnInit(): void {

    this.fillContractDurationDropdwon();

    this.contractForm = this.formBuilder.group({
      contactPerson: [null, Validators.required],
      contactDepartment: [null, Validators.required],
      contractName: [null, Validators.required],
      contractNumber: [null, Validators.required],
      customer: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
      contractDuration: [null, Validators.required],
      contractOwner: [null],
      requestType: [null, Validators.required]
    });

    this.getContractData();



  }

}
