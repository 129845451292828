import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from '../../../environments/environment';
import { ConstData } from '../../const-data/const-data';

@Component({
  selector: 'app-functional-requirements',
  templateUrl: './functional-requirements.component.html',
  styleUrls: ['./functional-requirements.component.scss']
})
export class FunctionalRequirementsComponent implements OnInit {

  @ViewChild('FunctionalRequirementsChart') functionalRequirementsChart!: ElementRef;
  @ViewChild(BaseChartDirective) baseChart!: BaseChartDirective;

  usedFunctions: string[] = ['', '', ''].concat(ConstData.getUsableFunctions());

  functionOverviewData!: {contract_name: string}[];
  maxUpgradeYear = 2040;

  public bubbleChartOptions: ChartOptions =  {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          min: 2024,
          max: this.maxUpgradeYear,
          stepSize: 1
        }
      }],
      yAxes: [{
        ticks: {
          min: 0, max: this.usedFunctions.length,
          callback: (value: number) => this.usedFunctions[value],
          stepSize: 1,
        },
      }]
    },
    tooltips: {
      callbacks: {
        label: (item, data) =>
        {
          return this.functionOverviewData[item.index!]!.contract_name + ' require ' + this.usedFunctions[parseInt(item.yLabel!.toString())] + ' in ' + item.xLabel;
        }
      }
    }
  };

  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = false;
  public bubbleChartData: ChartDataSets[] = [];
  constructor(private loadingSpinner: SiNewtonLoadingService, private httpService: HttpClient) {
    console.log('Functional Requirements Constructor');
    this.fillUsedFunctionsIntoChart();
  }

  ngOnInit(): void {
    console.log('Functional Requirements NgOnInit');
  }

  public async fillUsedFunctionsIntoChart(){

    console.log('Functional Requirements fillUsedFunctionsIntoChart');
    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-overview/get-function-overview';

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    this.httpService.get(endpoint, options).subscribe(async (response: any) => {
      this.functionOverviewData = response;

      let dataForChart = [];
      for (let index = 0; index < response.length; index++) {

        let indexOfFunction = this.usedFunctions.findIndex((element) => element.replace(/ /g, '') ==  response[index].function_name.replace(/ /g, ''));

        if(indexOfFunction >= 0){
          dataForChart.push({
            x: response[index].upgrade_pcs_neo_year,
            y: indexOfFunction,
            r: 5 +  5 * response.filter((element:any) => element.function_name == response[index].function_name &&
              element.upgrade_pcs_neo_year == response[index].upgrade_pcs_neo_year).length
          });
        }
      }

      this.bubbleChartData = [{data: dataForChart}];

      this.baseChart.chart.render();

      this.loadingSpinner.stopLoading();
    }, (errorResponse: HttpErrorResponse) => {
      this.bubbleChartData = [];
      this.loadingSpinner.stopLoading();
    })
  }

}
