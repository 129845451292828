import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { SiNewtonDropdownModule } from '@simpl/newton-ng/dropdown';

import { SiNewtonHeader2Module } from '@simpl/newton-ng/header2';
import { SiNewtonFooterModule } from '@simpl/newton-ng/footer';
import { SiNewtonBreadcrumbModule } from '@simpl/newton-ng/breadcrumb';
import { SiNewtonHeaderImageModule } from '@simpl/newton-ng/header-image';
import { SiNewtonSectionModule } from '@simpl/newton-ng/section';
import { SiNewtonTextModule } from '@simpl/newton-ng/text';
import { SiNewtonTableModule } from '@simpl/newton-ng/table';

import { SiNewtonToastService } from '@simpl/newton-ng/toast';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SiNewtonFormGroupModule } from '@simpl/newton-ng/form-group';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from './error-dialog.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { HwReferenceChangeManagementComponent } from './pages/hw-reference-change-management/hw-reference-change-management.component';

import { UserManagementService } from './services/user-management.service';
import { ContractListComponent } from './dashboard-tab-list/contract-list/contract-list.component';
import { UploadBomComponent } from './modals/upload-bom/upload-bom.component';
import { HwCheckOverviewModalComponent } from './modals/hw-check-overview-modal/hw-check-overview-modal.component';
import { UpgradeTimesModalComponent } from './modals/upgrade-times-modal/upgrade-times-modal.component';
import { FunctionTableComponent } from './sub-views/function-table/function-table.component';
import { ContractCreationComponent } from './dashboard-tab-list/contract-creation/contract-creation.component';
import { AdditionalDataModalComponent } from './modals/additional-data-modal/additional-data-modal.component';
import { InterfaceDataComponent } from './sub-views/interface-data/interface-data.component';
import { AddonsDataComponent } from './sub-views/addons-data/addons-data.component';
import { LibrariesDataComponent } from './sub-views/libraries-data/libraries-data.component';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UpdateContractModalComponent } from './modals/update-contract-modal/update-contract-modal.component';
import { DeleteContractModalComponent } from './modals/delete-contract-modal/delete-contract-modal.component';
import { FunctionalRequirementsComponent } from './dashboard-tab-list/functional-requirements/functional-requirements.component';
import { DashboardTabListComponent } from './dashboard-tab-list/dashboard-tab-list.component';
import { EditMeaseurementsComponent } from './modals/hw-check-overview-modal/edit-measeurements/edit-measeurements.component';
import { HwCheckOverviewComponent } from './modals/hw-check-overview-modal/hw-check-overview/hw-check-overview.component';
import { ManagementviewComponent } from './dashboard-tab-list/managementview/managementview.component';
import { MlfbViewComponent } from './dashboard-tab-list/mlfb-view/mlfb-view.component';
import { ManageReleaseDocumentsComponent } from './modals/manage-release-documents/manage-release-documents.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ErrorDialogComponent,
    HwReferenceChangeManagementComponent,
    ContractListComponent,
    UploadBomComponent,
    HwCheckOverviewModalComponent,
    UpgradeTimesModalComponent,
    FunctionTableComponent,
    ContractCreationComponent,
    AdditionalDataModalComponent,
    InterfaceDataComponent,
    AddonsDataComponent,
    LibrariesDataComponent,
    UpdateContractModalComponent,
    DeleteContractModalComponent,
    FunctionalRequirementsComponent,
    DashboardTabListComponent,
    EditMeaseurementsComponent,
    HwCheckOverviewComponent,
    ManagementviewComponent,
    MlfbViewComponent,
    ManageReleaseDocumentsComponent
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
     //Auth0
     AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
    // SiMPL Peers
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    // SiMPL
    SiNewtonBreadcrumbModule,
    SiNewtonFooterModule,
    SiNewtonHeaderImageModule,
    SiNewtonHeader2Module,
    SiNewtonSectionModule,
    SiNewtonTextModule,
    SiNewtonTableModule,
    SiNewtonFormGroupModule,
    SiNewtonDropdownModule,
    FormsModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    // Application local
    AppRoutingModule,
    HttpClientModule,
    ChartsModule,
    MatSlideToggleModule
  ],
  exports: [
      MatSlideToggleModule
  ],
  providers: [HttpClientModule, SiNewtonToastService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  }, UserManagementService],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
