<div *ngIf="(userManagementService.getUserRole() | async ) === 'user' || (userManagementService.getUserRole() | async ) === 'admin' || (userManagementService.getUserRole() | async ) === 'orga'" >
  <si-newton-header mobileTitle="LCS Contract Dashboard">

  <ul si-newton-header-menu>
    <li siNewtonHeaderMenuItem>
      <a class="nav-link" routerLink="">Home</a>
    </li>
    <li siNewtonHeaderMenuItem title="Contracts">
      <a class="nav-link" routerLink="contracts">
        Contracts
      </a>
    </li>
    <li siNewtonHeaderMenuItem *ngIf="(userManagementService.getUserRole() | async )  == 'admin'">
      <a class="nav-link" routerLink="hw-reference-change-management">
        Reference Management
      </a>
    </li>

  </ul>

  <si-newton-header-sag-logo class="navbar-header"></si-newton-header-sag-logo>

  <si-newton-header-navbar-search></si-newton-header-navbar-search>

  <ul siNewtonHeaderTopRightMenu>
    <li class="application-title">
      LCS Contract Dashboard
    </li>
    <li siNewtonHeaderMenuItem>
      <a class="nav-link" href="#">
        <div class="user-profile">
          <img src="favicon.png" alt="SiMPL">
        </div>
        <span>Home</span>
      </a>
    </li>
    <li siNewtonHeaderMenuItem>
      <a class="nav-link" href="#">
        <i class="icon-left newton-email"></i>
        <span>Contact</span>
      </a>

      <div class="nav-dropdown-menu" *siNewtonHeaderMenuDropdown>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="nav-dropdown-right-divider"></div>
              <ul class="header-link-list">
                <li>
                  <a>jester.juergen@siemens.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</si-newton-header>

<main [@routerFadeAnimation]="getRouteAnimation(route)">
  <si-newton-breadcrumb></si-newton-breadcrumb>
  <router-outlet #route="outlet"></router-outlet>
</main>

<si-newton-footer applicationName="LCS Contract Dashboard">
</si-newton-footer>

</div>


<div *ngIf="(userManagementService.getUserRole() | async ) === 'noRole'"> 
  
  <si-newton-section>
    <b>If you have rights to access this site, you will redirected. If not, please ask for access.</b>
  </si-newton-section>
</div>