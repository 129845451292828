import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { CustomHttpService } from 'src/app/services/custom-http.service';
import { environment } from '../../../environments/environment';
import { CustomToastService } from '../../services/custom-toast.service';

interface Function {
  function_name: string;
  customer_request: boolean;
  release_month: number;
  release_year: number;
}

@Component({
  selector: 'app-function-table',
  templateUrl: './function-table.component.html',
  styleUrls: ['./function-table.component.scss']
})
export class FunctionTableComponent implements OnInit {

  @Input()
  userRole!: string;

  @ViewChild('auditFileSelection', { static: true })
  auditFileSelection: ElementRef<HTMLInputElement> | undefined;

  auditFile: File | undefined;

  @Input()
  upgradeMonth!: number;

  @Input()
  upgradeYear!: number;

  @Input()
  contractId!: number;

  showImportOptions!: boolean;

  functions: Array<Function> = [];

  async storeFile(){

    console.log(this.auditFileSelection);

    this.auditFile = this.auditFileSelection?.nativeElement.files?.item(0) || undefined;

  }


  async importAuditFile(){

    const formData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('auditFile', this.auditFile!);

    this.loadingService.startLoading();

    await this.customHttpService.defaultHttpPostObserveBodyJson('/contract-management/import-audit-file', formData);

    this.getFunctionsWithRequestStatus();

  }

  updateContractFuntion(functionName: string, functionChecked: boolean){

    this.loadingService.startLoading();

    let endpoint;

    if(!functionChecked){
      endpoint = environment.baseUrl + '/contract-management/insert-contract-function';
    } else {
      endpoint = environment.baseUrl + '/contract-management/delete-contract-function';
    }

    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('functionName', functionName.toString());

    const options = {
      responseType: 'text' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

      this.loadingService.stopLoading();
    }, error => {
      this.toastService.showErrorToast(error.errorMessage);
      this.loadingService.stopLoading();
    })

  }

  checkAivalability(functionMonth: number, functionYear: number): string{

    if(functionYear > this.upgradeYear){
      return 'not available';
    } else if (functionYear == this.upgradeYear && functionMonth <= this.upgradeMonth){
      return 'available';
    } else if (functionYear < this.upgradeYear) {
      return 'available';
    } else  {
      return 'critical';
    }
  }

  getFunctionsWithRequestStatus(){

    const endpoint = environment.baseUrl + `/sw-availablity/get-functions-roadmap?contractId=${this.contractId}`;

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    this.httpService.get(endpoint, options).subscribe((result: any) => {
      this.functions = result;
    })
  }


  constructor(private httpService: HttpClient, private toastService: CustomToastService,
    private loadingService: SiNewtonLoadingService, private customHttpService: CustomHttpService) {

    this.showImportOptions = false;
  }

  ngOnInit(): void {
    this.getFunctionsWithRequestStatus();
  }

}
