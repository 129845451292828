<si-newton-section style="margin-top: 0px">
  <div class="row">
    <div class="col-md-12">
      <h2 id="tab-panels">Reference Management</h2>

      <div class="row" style="margin: 0px;">
        <h4>Hardware Database
        <span style="font-weight: 400; font-size: medium;">Last Upload: {{uploadDateHwDatabase}}</span></h4>
      </div>

      <div class="row" style="margin: -1em 0em 1em 0em">

        <label class="btn btn-primary col-md-4" style="float: left; margin: 5px;">
          Select new version of HW-Database
          <span class="newton-search"></span>
          <input #dataBaseSelection type="file"
            accept="application/msexcel"
            (change)="storeFile()"
          >
        </label>

        <button type="button" class="btn btn-success col-md-3" style="float: right; margin: 5px;" (click)="uploadHWDataBase()">Upload HW Database</button>
        <button type="button" class="btn btn-secondary col-md-3" style="float: right; margin: 5px;" (click)="downloadComparison()">Compare with current version</button>

        </div>


        <div class="row" style="margin: 0px;">
          <h4>Custom HW reference
          <span style="font-weight: 400; font-size: medium;">Last Upload: {{uploadDateCustomHwReference}}</span></h4>
        </div>

        <div class="row" style="margin: -1em 0em 1em 0em">
          <label class="btn btn-primary col-md-4" style="float: left; margin: 5px;">
            Select custom reference file
            <span class="newton-search"></span>
            <input #customHwDataSelection type="file"
              accept="application/msexcel"
              (change)="storeCustomHwData()"
            >
          </label>

          <button type="button" class="btn btn-success col-md-3" style="float: right; margin: 5px;" (click)="uploadCustomHwData()">Upload custom HW Reference</button>
          <button type="button" class="btn btn-secondary col-md-3" style="float: right; margin: 5px;" (click)="downloadReferenceFile('custom-hardware-reference', 'custom-hw-reference.xlsx')">Download custom HW Reference</button>

        </div>

        <div class="row" style="margin: 0px;">
          <h4>"Not included" Components File
          <span style="font-weight: 400; font-size: medium;">Last Upload: {{uploadDateOfNotInclidedElementsFile}}</span></h4>
        </div>
        <div class="row" style="margin: -1em 0em 1em 0em">
          <label class="btn btn-primary col-md-4" style="float: left; margin: 5px;">
            Select file with not included elements
            <span class="newton-search"></span>
            <input #notIncludedElementsSelection type="file"
              accept="application/msexcel"
              (change)="storeNotIncludedElementsFile()"
            >
          </label>

          <button type="button" class="btn btn-success col-md-3" style="float: right; margin: 5px;" (click)="uploadNotIncludedElementsFile()">Upload File with not included elements</button>
          <button type="button" class="btn btn-secondary col-md-3" style="float: right; margin: 5px;" (click)="downloadReferenceFile('not-included-elements', 'HW-Components_not_included.xlsx')">Download File with not included elements</button>

        </div>



        <div class="row" style="margin: 0px">
          <h4>Function Roadmap
          <span style="font-weight: 400; font-size: medium;">Last Upload: {{uploadDateFunctionRoadmap}}</span></h4>
        </div>

        <div class="row" style="margin: -1em 0em 1em 0em">
          <label class="btn btn-primary col-md-4" style="float: left; margin: 5px;">
            Select new Function Roadmap file
            <span class="newton-search"></span>
            <input #functionRoadmapSelection type="file"
              accept="application/msexcel"
              (change)="storeFunctionRoadmap()"
            >
          </label>

          <button type="button" class="btn btn-success col-md-3" style="float: right; margin: 5px;" (click)="uploadFunctionRoadmap()">Upload new function Roadmap</button>
          <button type="button" class="btn btn-secondary col-md-3" style="float: right; margin: 5px;" (click)="downloadReferenceFile('function-roadmap', 'Function-Roadmap.xlsx')">Download Function Roadmap</button>


        </div>

    </div>
  </div>
</si-newton-section>
