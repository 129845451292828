import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, defaultIfEmpty } from 'rxjs/operators';
import authConfig from '../../../auth_config.json';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private profileSubject = new BehaviorSubject<User | null>(null);
  public profile$ = this.profileSubject.asObservable();

  constructor(public auth: AuthService) {
    this.auth.user$.pipe(
      filter(profile => profile !== null),
      take(1)
    ).subscribe(profile => {
      this.profileSubject.next(profile ?? null);
    });
  }

  public getUserRole(): Observable<string> {
    console.log('getUserRole');
    return this.profile$.pipe(
      map(profile => {
        if (profile && (profile[authConfig.rolesTag] || profile['role'])) {
          return profile;
        }
        return null;
      }),
      map(profile => {
        if (!profile) {
          return 'noRole';
        }
        
        const roles = profile[authConfig.rolesTag] || profile['role'] as string[];
  
        if (roles.includes(environment.roles.adminRole)) {
          return 'admin';
        } else if (roles.includes(environment.roles.userRole)) {
          return 'user';
        } else if (roles.includes(environment.roles.orgaRole)) {
          return 'orga';
        } else {
          return 'noRole';
        }
      }),
      defaultIfEmpty('noRole'),
    );
  }
  
  // public getUserRole(): Observable<string> {

  //   console.log('getUserRole')
  //   return this.profile$.pipe(
  //     tap(profile => console.log('Before filter:', profile)),
  //     filter(profile => profile !== null && profile !== undefined && profile[authConfig.rolesTag]),
  //     tap(profile => console.log('After filter:', profile)),
  //     map(profile => {
  //       const roles = profile ? (profile[authConfig.rolesTag] || profile['role']) as string[] : [];

  //       if (roles && roles.includes('PCSneo Contract Dashboard Admin')) {
  //         return 'admin';
  //       } else if (roles && roles.includes('PCSneo Contract Dashboard User')) {
  //         return 'user';
  //       } else if (roles && roles.includes('PCSneo Contract Dashboard Management')){
  //         return 'orga';
  //       } else {
  //         return 'noRole'
  //       }
  //     }),
  //     tap(role => console.log('Mapped role:', role)),
  //     defaultIfEmpty('noRole'),
  //     tap(role => console.log('Final role:', role))
  //   );
  // }

 

  public getUsermail(): Observable<string> {
    return this.profile$.pipe(
      filter(profile => profile != null && profile.email != null),
      map(profile => {
        return profile?.email || '';
      })
    );
  }

}
