<div class="modal-header">
  <h4 class="modal-title pull-left">Upload BOM</h4>
</div>
<div class="modal-body">
  <label class="btn btn-primary" style="width: 50%;">
    Select BOM
    <span class="newton-search"></span>
    <input #bomFileSelection type="file"
      accept="application/msexcel"
      (change)="storeBomFile()">
  </label>


  <button type="button"
    class="btn btn-success"
    style="width: 50%;"
    (click)="uploadBom()"
    [disabled]="bomFileSelection === undefined">
    Upload BOM
  </button>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modalRef.hide()">{{closeBtnName}}</button>
</div>
