<div class="modal-header">
  <h4 class="modal-title pull-left">Edit HW + SW-Upgrade for LCS contract: {{contractName}}</h4>

  <button (click)="toggleContractDataView()" class="btn btn-secondary">
    <div *ngIf="!contractDataVisible">
      Show Contract Data <span class="newton-arrow-down-large" style="margin-left: 1em;"></span>
    </div>
    <div *ngIf="contractDataVisible">
      Hide Contract Data <span class="newton-arrow-up-large" style="margin-left: 1em;"></span>
    </div>
  </button>

</div>
<div #modalBody class="modal-body">

  <form [formGroup]="editUpgradeTimesForm" class="col-md-12" style="align-items: center;">

    <div class="row" *ngIf="contractDataVisible">
      <div class="col">
        <si-newton-form-group label="Contract name">
          <input type="string" [ngModel]="contractName"
          formControlName="contractName" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>
      <div class="col">
        <si-newton-form-group label="Contract number">
          <input type="string" [ngModel]="contractNumber"
          formControlName="contractNumber" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row" *ngIf="contractDataVisible">
      <div class="col" style="max-width: 50%;">
        <si-newton-form-group label="Customer">
          <input type="string" [ngModel]="customer"
          formControlName="customer" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col" style="max-width: 50%;">
        <si-newton-form-group label="Contract Duration">
          <input type="string" [ngModel]="contractDuration"
          formControlName="contractDuration" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Year of LCS Start">
          <input type="number" [ngModel]="lcsStartYear"
          formControlName="lcsStartYear" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="End of LCS">
          <input type="number" [ngModel]="lcsEndYear"
          formControlName="lcsEndYear" [readOnly]="true" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Select value of upgrade cycle">

          <si-newton-dropdown
          id="lcsUpgradeCycle"
          [dropdownOptions]="lcsUpgradeCycleValues"
          [valueProvider]="getLcsUpgradeCycleValues"
          formControlName="lcsUpgradeCycle"
          (ngModelChange)="updateUpgradeCycle();updateStandardValueOfFirstUpgradeYear();fillCustomUpgradeYearDropdownValues();updateUpgradeYears();updatePcsNeoUpgrade();">
        </si-newton-dropdown>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">

      <div class="col">

        <div class="form-check">
          <input id="radio-a" class="form-check form-check-inline"
                formControlName="upgradeScheduling"
                [(ngModel)]="upgradeScheduling"
                type="radio"
                value="standard"
                (change)="updateUpgradeYears();updatePcsNeoUpgrade();"
                >
          <label for="radio-a" class="form-check-label">
            Standard Year of 1st Upgrade
          </label>
        </div>


        <div class="form-check">
          <input id="radio-b" class="form-check form-check-inline"
                formControlName="upgradeScheduling"
                [(ngModel)]="upgradeScheduling"
                type="radio"
                value="manually"
                (change)="updateUpgradeYears();updatePcsNeoUpgrade();"
                >
          <label for="radio-b" class="form-check-label">
            Custom Year of 1st Upgrade
          </label>
        </div>

      </div>

      <div class="col">
        <si-newton-form-group label="Select upgrade month">

          <si-newton-dropdown
          name="upgradeMonth"
          formControlName="upgradeMonth"
          [dropdownOptions]="upgradeMonthOptions"
          [valueProvider]="getUpgradeMonthValues"
          (ngModelChange)="updateUpgradeMonth()">
        </si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col">


        <si-newton-form-group *ngIf="upgradeScheduling==='standard'" label="Year of first upgrade">
          <input type="number" id="standardFirstUpgradeYear" [(ngModel)]="standardFirstUpgradeYear"
          formControlName="standardFirstUpgradeYear" [readOnly]="true" siNewton>
        </si-newton-form-group>

        <si-newton-form-group *ngIf="upgradeScheduling==='manually'" label="Select year of first upgrade">

          <si-newton-dropdown
          name="customFirstUpgradeYear"
          formControlName="customFirstUpgradeYear"
          [dropdownOptions]="dropdownCustomUpgradeYears"
          [valueProvider]="getDropdownUpgradeYearValues"
          (ngModelChange)="updateCustomValueOfFirstUpgradeYear();updateUpgradeYears();updatePcsNeoUpgrade();">
        </si-newton-dropdown>
        </si-newton-form-group>
      </div>

    </div>

    <div class="upgradeOverview">

        <ul [ngClass]="(lcsEndYear - lcsStartYear) == 10 ? 'contractYears tenYears' : 'contractYears'">
          <li *ngFor="let item of [].constructor(lcsEndYear - lcsStartYear); let i = index"
          [ngClass]=
          "setUpgradeContractYears.includes((i+1)) && (i+1) < neoUpgradeContractYear ? 'upgrade pcs7' :
          setUpgradeContractYears.includes((i+1)) && (i+1) == neoUpgradeContractYear ? 'pcsNeoUpgrade' :
          setUpgradeContractYears.includes((i+1)) && (i+1) > neoUpgradeContractYear ? 'pcsNeoUpdate' :
          (i+1) < neoUpgradeContractYear ? ' pcs7' : ' pcsNeo'">
            {{ i+1 }}
          </li>
        </ul>


        <ul [ngClass]="(lcsEndYear - lcsStartYear) == 10 ? 'years tenYears' : 'years'">
          <li *ngFor="let item of [].constructor(lcsEndYear - lcsStartYear); let i = index" class="year">
            {{ lcsStartYear + i }}
          </li>
        </ul>



    </div>

    <div style="margin-top: 15px; font-weight: bold; font-size: large;">
      <div *ngIf="secondUpgradePossible() && !thirdUpgradePossible() && wouldBeSecondUpgradeInLastYear()" class="form-check form-check-inline" style="float: right;">
        <input #secondUpgradeCb class="form-check-input" formControlName="secondUpgradeCb" type="checkbox"
        [(ngModel)]="secondUpgradeChecked" (change)="secondUpgradeToggle();updateUpgradeYears();updatePcsNeoUpgrade()">
        <label for="secondUpgradeCb" class="form-check-label">
          2nd Upgrade?
        </label>
      </div>
      <div *ngIf="thirdUpgradePossible() && wouldBeThirdUpgradeInLastYear()" class="form-check form-check-inline" style="float: right;">
        <input #thirdUpgradeCb class="form-check-input" formControlName="thirdUpgradeCb" type="checkbox"
        [(ngModel)]="thirdUpgradeChecked" (change)="thirdUpgradeToggle();updateUpgradeYears();updatePcsNeoUpgrade()">
        <label for="thirdUphradeCb" class="form-check-label">
          3rd Upgrade?
        </label>
      </div>

      <div class="form-check neoUpgradeChoice">
        <input id="neoUpgrade0" class="form-check form-check-inline" formControlName="neoUpgrade"
              type="radio"
              [(ngModel)]="upgradePcsNeo"
              [value]=0
              (change)="updatePcsNeoUpgrade()"
              >
        <label for="neoUpgrade0" class="form-check-label">
          No PCS neo Evolution
        </label>
      </div>
      <div class="form-check neoUpgradeChoice">
        <input id="neoUpgrade1" class="form-check form-check-inline" formControlName="neoUpgrade"
              type="radio"
              [(ngModel)]="upgradePcsNeo"
              [value]=1
              (change)="updatePcsNeoUpgrade()"
              >
        <label for="neoUpgrade1" class="form-check-label">
          PCS neo Evolution in first Upgrade
        </label>
      </div>
      <div *ngIf="secondUpgradePossible();secondUpgradeChecked" class="form-check neoUpgradeChoice">
        <input id="neoUpgrade2" class="form-check form-check-inline" formControlName="neoUpgrade"
              type="radio"
              [(ngModel)]="upgradePcsNeo"
              [value]=2
              (change)="updatePcsNeoUpgrade()"
              >
        <label for="neoUpgrade2" class="form-check-label">
          PCS neo Evolution in second Upgrade
        </label>
      </div>
      <div *ngIf="thirdUpgradePossible() && thirdUpgradeChecked" class="form-check neoUpgradeChoice">
        <input id="neoUpgrade3" class="form-check form-check-inline" formControlName="neoUpgrade"
              type="radio"
              [(ngModel)]="upgradePcsNeo"
              [value]=3
              (change)="updatePcsNeoUpgrade()"
              >
        <label for="neoUpgrade3" class="form-check-label">
          PCS neo Evolution in third Upgrade
        </label>
      </div>


      <app-function-table [upgradeMonth]="upgradeMonth" [upgradeYear]="neoUpgradeYear"
        [contractId]="contractId" [userRole]="userRole"></app-function-table>

    </div>

  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="downloadReport()">Download report</button>
  <button *ngIf="userRole != 'orga'" type="button" class="btn btn-success" (click)="modalRef.hide()">Save & Close</button>
  <button *ngIf="userRole == 'orga'" type="button" class="btn btn-success" (click)="modalRef.hide()">Close</button>
</div>


