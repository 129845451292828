<si-newton-header-image imageURL="assets/images/brand-banner.png" height="300px"></si-newton-header-image>
<si-newton-section>
  <div class="row">
    <si-newton-text>
      <h2>LCS Contract Dashboard</h2>

      <ul style="font-size: 1.2em;">

        <img src="../../../assets/contract dashboard overview.JPG" style="height: 100%; width: 100%; margin-bottom: 10px;">

        <li style="margin-bottom: 5px;">
          The <i>LCS contract Dashboard</i> supports the whole Evaluation-Process for LCS contracts
          with an integrated <i>Evolution to PCS neo</i>.
        </li>
        <li style="margin-bottom: 5px;">
          Basic checks, like <i>SW-Function-Availabiltity</i> for the scheduled evolution to PCS neo
          and the <i>PCS neo HW-Compatibility Check</i> can be executed within this dashboard.
        </li>
        <li style="margin-bottom: 5px;">
          Basis for this checks are the actual, official PCS neo roadmap and the information about
          compatibility of HW-components to PCS neo from <i>DI PA AE</i>.
        </li>
        <li style="margin-bottom: 5px;">
          All check-results are archived per <i>PCS neo LCS contract</i> - request as a basis for HQ-LoA decision.
        </li>
        <li style="margin-bottom: 5px;">
          All documents and references related to the risk-evaluation, HQ-LoA decision etc. can be
          archived, directly assigned to the individual <i>PCS neo LCS contract</i>.
        </li>
        <li style="margin-bottom: 5px;">
          References like <i>PCS neo Roadmap</i> and <i>PCS neo HW-Release-database</i> will be updated monthly.
        </li>
        <li style="margin-bottom: 5px;">
          In case of changes in mentioned references, all archived <i>PCS neo LCS contract</i> - requests are checked
          automatically, in terms of impacts to the SW-availability or HW-compatibility.
        </li>
        <li style="margin-bottom: 5px;">
          LCS Contracts which are impacted will be highlighted and a clarification process between
          <i>DI PA AE</i> and <i>DI CS PA</i> will be started at an early stage.
        </li>
      </ul>


      </si-newton-text>
  </div>
</si-newton-section>
