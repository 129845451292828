import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


export interface DropDownOption {
  key: string;
  value: string;
}
@Component({
  selector: 'app-upgrade-times-modal',
  templateUrl: './upgrade-times-modal.component.html',
  styleUrls: ['./upgrade-times-modal.component.scss'],
})
export class UpgradeTimesModalComponent implements OnInit {
  @ViewChild('modalBody') modalBody!: ElementRef;
  reportImage!: any;

  contractDataVisible!: boolean;
  userRole!: string;

  // Needed for Writing new values to database
  contractId!: number;

  editUpgradeTimesForm: FormGroup;

  //ngModelValues
  contractName!: string;
  contractNumber!: string;
  customer!: string;
  contractDuration!: number;
  standardFirstUpgradeYear!: number;
  upgradePcsNeo!: number;
  lcsStartYear!: number;
  lcsEndYear!: number;
  upgradeScheduling!: string;

  //Values will set by Dropdown selection
  upgradeMonth!: number;
  upgradeCycle!: number;
  customFirstUpgradeYear!: number;


  // Calculated values
  setFirstUpgradeYear!: number;
  setSecondUpgradeYear!: number;
  setThirdUpgradeYear!: number;
  neoUpgradeYear!: number;

  // Example: 2025 => 5, 2030 => 10, 2035 => 15
  setUpgradeContractYears!: number[];
  neoUpgradeContractYear!: number;

  // Checkbox Values
  thirdUpgradeChecked!: boolean;
  secondUpgradeChecked!: boolean;

  //Dropdown Options
  public dropdownCustomUpgradeYears: Array<DropDownOption> = [];
  public lcsUpgradeCycleValues: Array<DropDownOption> = [];
  public upgradeMonthOptions: Array<DropDownOption> = [];

  toggleContractDataView() {
    this.contractDataVisible = !this.contractDataVisible;
  }

  thirdUpgradePossible(): boolean {
    if (
      this.setSecondUpgradeYear > 0 &&
      this.setSecondUpgradeYear + this.upgradeCycle <= this.lcsEndYear - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  wouldBeThirdUpgradeInLastYear(): boolean {
    if (
      this.setFirstUpgradeYear + this.upgradeCycle * 2 ==
      this.lcsEndYear - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  secondUpgradePossible(): boolean {
    if (
      this.setFirstUpgradeYear > 0 &&
      this.setFirstUpgradeYear + this.upgradeCycle <= this.lcsEndYear - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  wouldBeSecondUpgradeInLastYear(): boolean {
    if (this.setFirstUpgradeYear + this.upgradeCycle == this.lcsEndYear - 1) {
      return true;
    } else {
      return false;
    }
  }

  fillUpgradeCycleDropdownValues() {
    this.lcsUpgradeCycleValues = [];

    this.lcsUpgradeCycleValues.push({
      key: '5',
      value: '5',
    });

    if (this.lcsEndYear - this.lcsStartYear > 5) {
      this.lcsUpgradeCycleValues.push({
        key: '6',
        value: '6',
      });
    }
  }

  fillUpgradeMonthDropdownValues() {
    for (let i = 1; i <= 12; i++) {
      this.upgradeMonthOptions.push({
        key: i.toString(),
        value: i.toString(),
      });
    }
  }

  private setDropdownSelection(formControlName: string, selection: number) {
    this.editUpgradeTimesForm.controls[formControlName].setValue({
      key: selection.toString(),
      value: selection.toString(),
    });
  }

  public getLcsUpgradeCycleValues(lcsUpgradeCycleValues: DropDownOption): string {
    return lcsUpgradeCycleValues.value.toString();
  }

  public getUpgradeMonthValues(upgradeMonthOptions: DropDownOption): string {
    return upgradeMonthOptions.value.toString();
  }

  fillCustomUpgradeYearDropdownValues() {
    this.dropdownCustomUpgradeYears = [];

    this.dropdownCustomUpgradeYears.push({
      key: this.lcsStartYear.toString(),
      value: this.lcsStartYear.toString(),
    });

    for (let i = 1; i < this.upgradeCycle; i++) {
      this.dropdownCustomUpgradeYears.push({
        key: (this.lcsStartYear + i).toString(),
        value: (this.lcsStartYear + i).toString(),
      });
    }
  }

  public getDropdownUpgradeYearValues(dropdownUpgradeYears: DropDownOption): string {
    return dropdownUpgradeYears.value.toString();
  }

  updateStandardValueOfFirstUpgradeYear() {
    if (this.lcsStartYear !== null) {
      this.standardFirstUpgradeYear = this.lcsStartYear + this.upgradeCycle - 1;
    }
  }

  updateCustomValueOfFirstUpgradeYear() {
    this.customFirstUpgradeYear = parseInt(
      this.editUpgradeTimesForm.get('customFirstUpgradeYear')?.value.value
    );
  }

  updateUpgradeYears() {
    // Upgrade Years showed in Contract Time, e.g. 2020, 2025, 2030 => 5, 10 and 15
    this.setUpgradeContractYears = [];

    if (this.upgradeScheduling === 'standard') {
      this.setFirstUpgradeYear = this.standardFirstUpgradeYear;
    } else {
      this.setFirstUpgradeYear = this.customFirstUpgradeYear;
    }

    const calculatedSecondUpgradeYear =
      this.setFirstUpgradeYear + this.upgradeCycle;
    const calculatedThirdUpgradeYear =
      calculatedSecondUpgradeYear + this.upgradeCycle;

    // Needed since user could have unchecked the 2nd / 3rd Upgrade Checkbox while last update was
    // calculated in last update and user did'n want this update. Buf if the last upgrade is calculated
    // for a year before the last year, then the upgrade is mandatory
    if (
      !this.secondUpgradeChecked &&
      calculatedSecondUpgradeYear < this.lcsEndYear - 1
    ) {
      this.secondUpgradeChecked = true;
    }

    if (
      !this.thirdUpgradeChecked &&
      calculatedThirdUpgradeYear < this.lcsEndYear - 1
    ) {
      this.thirdUpgradeChecked = true;
    }

    this.setUpgradeContractYears.push(
      this.setFirstUpgradeYear - this.lcsStartYear + 1
    );

    if (this.secondUpgradeChecked && this.secondUpgradePossible()) {
      this.setSecondUpgradeYear = calculatedSecondUpgradeYear;
    } else {
      this.setSecondUpgradeYear = 0;
    }

    this.setUpgradeContractYears.push(
      this.setSecondUpgradeYear - this.lcsStartYear + 1
    );

    if (this.thirdUpgradeChecked && this.thirdUpgradePossible()) {
      this.setThirdUpgradeYear = calculatedThirdUpgradeYear;
    } else {
      this.setThirdUpgradeYear = 0;
    }

    this.setUpgradeContractYears.push(
      this.setThirdUpgradeYear - this.lcsStartYear + 1
    );
  }

  updateUpgradeCycle() {
    this.upgradeCycle = parseInt(this.editUpgradeTimesForm.get('lcsUpgradeCycle')?.value.value);
  }

  secondUpgradeToggle() {
    if (!this.secondUpgradeChecked && this.upgradePcsNeo == 2) {
      this.upgradePcsNeo = 1;
    }
  }

  thirdUpgradeToggle() {
    if (!this.thirdUpgradeChecked && this.upgradePcsNeo == 3) {
      this.upgradePcsNeo = 2;
    }
  }

  updatePcsNeoUpgrade() {
    if (this.upgradePcsNeo == 0) {
      this.neoUpgradeYear = 9999;
      this.neoUpgradeContractYear = 9999;
    } else if (this.upgradePcsNeo == 1) {
      this.neoUpgradeYear = this.setFirstUpgradeYear;
      this.neoUpgradeContractYear = this.neoUpgradeYear - this.lcsStartYear + 1;
    } else if (this.upgradePcsNeo == 2 && this.secondUpgradePossible()) {
      this.neoUpgradeYear = this.setSecondUpgradeYear;
      this.neoUpgradeContractYear = this.neoUpgradeYear - this.lcsStartYear + 1;
    } else if (this.upgradePcsNeo == 3 && this.thirdUpgradePossible()) {
      this.neoUpgradeYear = this.setThirdUpgradeYear;
      this.neoUpgradeContractYear = this.neoUpgradeYear - this.lcsStartYear + 1;
    } else {
      this.neoUpgradeYear = 9999;
      this.neoUpgradeContractYear = 9999;

      this.upgradePcsNeo = 0;
    }
  }

  updateUpgradeMonth() {
    this.upgradeMonth = parseInt(this.editUpgradeTimesForm.get('upgradeMonth')?.value.value);
  }

  downloadReport() {

    this.loadingSpinner.startLoading();

    this.contractDataVisible = true;

    html2canvas(this.modalBody.nativeElement).then((canvas: any) => {

      this.reportImage = canvas.toDataURL();

      var pdf = new jsPDF('p', 'mm', [canvas.width, canvas.height]);

      pdf.internal.scaleFactor = 1;

      pdf.addImage(this.reportImage, 0, 0, canvas.width, canvas.height);

      pdf.save(this.contractName + '-sw-aivalability-report.pdf');

      this.loadingSpinner.stopLoading();
    });
  }

  updateUpgradeData(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/contract-management/update-upgrade-data';
    const formData: FormData = new FormData();

    formData.append('contractId', this.contractId.toString());
    formData.append('upgradeMonth', this.upgradeMonth.toString());
    formData.append('upgradeCycle', this.upgradeCycle.toString());
    formData.append('customFirstUpgradeYear', this.customFirstUpgradeYear.toString());
    formData.append('upgradeScheduling', this.upgradeScheduling);
    formData.append('upgradePcsNeo', this.upgradePcsNeo.toString());
    formData.append('upgradePcsNeoYear', this.neoUpgradeYear.toString());
    formData.append('secondUpgradeNeeded', this.secondUpgradeChecked.toString());
    formData.append('thirdUpgradeNeeded', this.thirdUpgradeChecked.toString());

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe((response: any) => {

        this.loadingSpinner.stopLoading();

        console.log(response.message);
    })

  }


  constructor(public modalRef: BsModalRef, private formBuilder: FormBuilder,
    private loadingSpinner: SiNewtonLoadingService, private httpService: HttpClient) {
    this.editUpgradeTimesForm = this.formBuilder.group({
      lcsStartYear: [null],
      lcsEndYear: [null],
      lcsUpgradeCycle: [null, Validators.required],
      customFirstUpgradeYear: [null],
      upgradeScheduling: [Validators.required],
      firstUpgradeYear: [null, Validators.required],
      secondUpgradeCb: [null],
      thirdUpgradeCb: [null],
      neoUpgrade: [Validators.required],
      upgradeMonth: [null, Validators.required],
      standardFirstUpgradeYear: [null],
      contractName: [null],
      contractNumber: [null],
      customer: [null],
      contractDuration: [null]
    });

  }

  ngOnInit(): void {
    this.contractDataVisible = true;

    this.fillUpgradeCycleDropdownValues();
    this.setDropdownSelection('lcsUpgradeCycle', this.upgradeCycle);

    this.fillCustomUpgradeYearDropdownValues();

    if (isNaN(this.customFirstUpgradeYear)) {
      this.customFirstUpgradeYear = this.lcsStartYear + this.upgradeCycle - 2;
    }

    this.setDropdownSelection(
      'customFirstUpgradeYear',
      this.customFirstUpgradeYear
    );

    this.fillUpgradeMonthDropdownValues();
    this.setDropdownSelection('upgradeMonth', this.upgradeMonth);

    this.setFirstUpgradeYear = this.customFirstUpgradeYear;
    this.setSecondUpgradeYear = this.setFirstUpgradeYear + this.upgradeCycle;
    this.setThirdUpgradeYear = this.setSecondUpgradeYear + this.upgradeCycle;

    this.neoUpgradeYear = 9999;
    this.neoUpgradeContractYear = 9999;

    if (!this.secondUpgradePossible()) {
      this.secondUpgradeChecked = false;
    }

    if (!this.thirdUpgradePossible()) {
      this.thirdUpgradeChecked = false;
    }

    this.updateUpgradeMonth();
    this.updateStandardValueOfFirstUpgradeYear();
    this.updateUpgradeYears();
    this.updatePcsNeoUpgrade();

    if(this.userRole == 'orga'){
      this.editUpgradeTimesForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.updateUpgradeData();

  }
}
