import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/services/user-management.service';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { CustomToastService } from '../../services/custom-toast.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './hw-reference-change-management.component.html',
  styleUrls: ['./hw-reference-change-management.component.scss']
})
export class HwReferenceChangeManagementComponent implements OnInit {

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(new HttpErrorResponse({
          error: JSON.parse(reader.result as string),
        }));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  hwDataBaseFile!: File;
  customHwDataFile!: File;
  functionRoadmapFile!: File;
  notIncludedElementsFile!: File;

  uploadDateCustomHwReference!: String;
  uploadDateHwDatabase!: String;
  uploadDateFunctionRoadmap!: String;
  uploadDateOfNotInclidedElementsFile!: String;


  @ViewChild('dataBaseSelection', { static: true })
  hwDataBaseFileInput!: ElementRef<HTMLInputElement>;

  @ViewChild('customHwDataSelection', { static: true })
  customHwDataFileInput!: ElementRef<HTMLInputElement>;

  @ViewChild('notIncludedElementsSelection', { static: true })
  notIncludedElementsFileInput!: ElementRef<HTMLInputElement>;

  @ViewChild('functionRoadmapSelection', { static: true })
  functionRoadmapFileInput!: ElementRef<HTMLInputElement>;

  async storeFile(){

    this.hwDataBaseFile = this.hwDataBaseFileInput!.nativeElement!.files!.item(0)!;
  }

  async storeCustomHwData(){

    this.customHwDataFile = this.customHwDataFileInput!.nativeElement!.files!.item(0)!;
  }

  async storeNotIncludedElementsFile(){

    this.notIncludedElementsFile = this.notIncludedElementsFileInput!.nativeElement!.files!.item(0)!;
  }

  async storeFunctionRoadmap(){

    this.functionRoadmapFile = this.functionRoadmapFileInput!.nativeElement!.files!.item(0)!;
  }

  public async uploadHWDataBase(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/upload-hw-database';

    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();

    const formData: FormData = new FormData();

    formData.append('file', this.hwDataBaseFile);
    formData.append('usermail', userMail);
    formData.append('informationType', 'hw-reference');

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe(
      response => {

        this.httpService.post(environment.baseUrl + '/hw-check/check-all-contracts', null, {
          responseType: 'text' as const
        }).subscribe(res => {

          this.toastService.showInfoToast('Checked all contracts with new HW References successfuelly');
          this.loadingSpinner.stopLoading();
        }, (errorResponse: HttpErrorResponse) => {
          this.loadingSpinner.stopLoading();
          this.toastService.showErrorToast((errorResponse as any).errorMessage);
        });
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorFromBackendToast(errorResponse);
      });

  }

  public async uploadNotIncludedElementsFile(){
    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/upload-file-with-not-included-elements';


    const formData: FormData = new FormData();
    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();


    formData.append('notIncludedElements', this.notIncludedElementsFile);
    formData.append('usermail', userMail);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe(
      response => {

        this.httpService.post(environment.baseUrl + '/hw-check/check-all-contracts', null, {
          responseType: 'text' as const
        }).subscribe(res => {

          this.toastService.showInfoToast('Checked all contracts with new information successfuelly');
          this.loadingSpinner.stopLoading();
        }, (errorResponse: HttpErrorResponse) => {
          this.loadingSpinner.stopLoading();
          this.toastService.showErrorFromBackendToast(errorResponse);
        });
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorFromBackendToast(errorResponse);
      });
  }

  public uploadFunctionRoadmap(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/upload-function-roadmap';

    const options = {
      responseType: 'json' as const
    };

    const formData: FormData = new FormData();

    formData.append('roadmapFile', this.functionRoadmapFile);

    this.httpService.post(endpoint, formData, options).subscribe(res => {
      this.httpService.post(environment.baseUrl + '/sw-availablity/check-all-contracts', null, {
        responseType: 'text' as const
      }).subscribe(res => {

        this.toastService.showInfoToast('Uploaded new SW reference and checked all contracts with new SW References successfuelly');
        this.loadingSpinner.stopLoading();
      }, (errorResponse: HttpErrorResponse) => {


        this.toastService.showErrorToast(errorResponse.error.errorMessage);
        this.loadingSpinner.stopLoading();
      });
    },
    (errorResponse: HttpErrorResponse) => {
      this.loadingSpinner.stopLoading();
      this.toastService.showErrorToast(errorResponse.error.errorMessage);
    });

  }

  public downloadReferenceFile(informationType: string, fileNameForDownload: string){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/download-reference-file';

    const options = {
      observe: 'body' as const,
      responseType: 'blob' as const,
      params: {
        ['informationType']: informationType
      }
    };

    this.httpService.get(endpoint, options).subscribe(
      blob => {

        this.loadingSpinner.stopLoading();

        saveAs(blob, fileNameForDownload);
      },
      async (errorResponse: HttpErrorResponse) => {

        this.loadingSpinner.stopLoading();
        const errorAsJson = await (new Response(errorResponse.error)).json();

        this.toastService.showErrorAsJsonFromBackendToast(errorAsJson);
      });
  }

  public async downloadComparison(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/compare-hw-reference';

    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();

    const formData: FormData = new FormData();

    formData.append('hwDataBase', this.hwDataBaseFile);
    formData.append('usermail', userMail);

    const options = {
      observe: 'body' as const,
      responseType: 'blob' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe(
      blob => {

        this.loadingSpinner.stopLoading();
        saveAs(blob, 'hw-reference-comparison.xlsx');
      },
      async (errorResponse: HttpErrorResponse) => {

        this.loadingSpinner.stopLoading();
        const errorAsJson = await new Response(errorResponse.error).json();
        this.toastService.showErrorAsJsonFromBackendToast(errorAsJson);
      });
  }

  public async uploadCustomHwData(){

    this.loadingSpinner.startLoading();

    const endpoint = environment.baseUrl + '/reference-management/upload-custom-hw-reference';

    const userMail = await this.userManagementService.getUsermail().pipe(take(1)).toPromise();

    const formData: FormData = new FormData();

    formData.append('customHwData', this.customHwDataFile);
    formData.append('usermail', userMail);

    const options = {
      responseType: 'json' as const
    };

    this.httpService.post(endpoint, formData, options).subscribe(
      response => {
        this.loadingSpinner.stopLoading();
        this.toastService.showInfoToast((response as any).message);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        this.toastService.showErrorToast(errorResponse.error.errorMessage);
      });


  }

  private getDateOfFile(informationType: string): Promise<string>{

    return new Promise( (resolve, reject) => {
      try {
        const endpoint = environment.baseUrl + '/reference-management/get-file-date';

        const options = {
          observe: 'body' as const,
          responseType: 'json' as const,
          params: {
            ['informationType']: informationType
          }
        };

        this.httpService.get(endpoint, options).subscribe((response: any) => {
          console.log(response.message);
          resolve(response.message);
        }, error => {
          resolve('Never');
        })

      } catch (err){
        reject('Never');
      }
      return '';
    })


  }

  constructor(private httpService: HttpClient, private userManagementService: UserManagementService,
    private loadingSpinner: SiNewtonLoadingService, private toastService: CustomToastService) {

    }

    ngOnInit(): void {

      this.getDateOfFile('function-roadmap').then(res => {
        this.uploadDateFunctionRoadmap = res;
      }).catch( () => {
        this.uploadDateFunctionRoadmap = 'Never';
      });

      this.getDateOfFile('hw-reference').then(res => {
        this.uploadDateHwDatabase = res;
      }).catch( () => {
        this.uploadDateHwDatabase = 'Never';
      });

      this.getDateOfFile('custom-hardware-reference').then(res => {
        this.uploadDateCustomHwReference = res;
      }).catch( () => {
        this.uploadDateCustomHwReference = 'Never';
      });

      this.getDateOfFile('not-included-elements').then(res => {
        this.uploadDateOfNotInclidedElementsFile = res;
      }).catch( () => {
        this.uploadDateOfNotInclidedElementsFile = 'Never';
      });
    }
}
